<template>
	<div>
		<div v-if="!revisionStatus[0]">
			<el-button  @click="NeedCorrect()">{{$store.state.teachlanguagedata?$store.state.teachlanguagedata.NeedsRevision:'需订正'}}</el-button>
		</div>
		<div v-else>
			<el-button v-if="(revisionStatus[0].audit_status!=4&&revisionStatus[0].audit_status!=3)" @click="NeedCorrect()">{{$store.state.teachlanguagedata?$store.state.teachlanguagedata.NeedsRevision:'需订正'}}</el-button>
			<el-button v-if="revisionStatus[0]&&revisionStatus[0].audit_status!=4&&revisionStatus[0].audit_status==3" @click="OkCorrected">{{$store.state.teachlanguagedata?$store.state.teachlanguagedata.ConfirmCorrection:'确认订正'}}</el-button>
		</div>
	</div>
</template>

<script>
	import {
		needCorrect,
		okCorrected,
		queryPlanUserJoinRecords
	} from '../../api/Correct.js'
	export default {
		name: 'Class',
		data() {
			return {
				recorretedData:'',//老师批改数据
				revisionStatus:'',//订正状态
			}
		},
		mounted() {
		},
		methods: {
			// 初始化
			init(userId,planId){
				this.userId=userId
				this.planId=planId
				this.recorretedData=this.$store.state.$recorretedDatas
				this.QueryPlanUserJoinRecords()
			},
			//需订正
			NeedCorrect(){
				let data={
					planId: this.planId,
					subId: this.recorretedData.item.id,
					userId: this.userId,
				}
				needCorrect(data).then(res=>{
					this.QueryPlanUserJoinRecords()
				})
			},
			// 确认订正
			OkCorrected(){
				let data={
					planId: this.planId,
					subId: this.recorretedData.item.id,
					userId: this.userId,
				}
				okCorrected(data).then(res=>{
					this.QueryPlanUserJoinRecords()
				})
			},
			// 订正状态
			QueryPlanUserJoinRecords(){
				let data={
					planId: this.planId,
					questionId: this.recorretedData.item.id,
					userId: this.userId,
				}
				queryPlanUserJoinRecords(data).then(res=>{
					this.revisionStatus = res.data.result
					this.$forceUpdate()
				})
			}
		}
	}
</script>

<style>
</style>