<template>
	<div>
		<div class="displayFlex margintopBottom20">
			<span style="white-space: nowrap;">
				切换题目：
			</span>
			<div>
				<el-button  @click="previousQuestion()">上一个错题</el-button>
				<el-button v-if="!$store.state.SubmitCheckStatuShow" @click="nextQuestion()">下一个错题</el-button>
				<el-button v-if="$store.state.SubmitCheckStatuShow" @click="SubmitCheckStatus()">批改完成是否提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		submitCheckStatus
	} from '../../api/base.js'
	export default {
		name: 'Class',
		data() {
			return {
			}
		},
		props:[
			'planId',
			'userId'
		],
		mounted() {
		},
		methods:{
			//提交已批改
			SubmitCheckStatus(){
				let data={
					planId:this.planId,
					userId:this.userId,
					checkStatus:'6',
				}
				submitCheckStatus(data).then(res=>{
					this.$message("批改完成,已提交")
					this.$emit('getdialogFormVisiblebi');
				})
			},
			previousQuestion(){
				this.$emit('previousQuestion');
			},
			nextQuestion(){
				this.$emit('nextQuestion');
			},
		},
	}
</script>

<style>
</style>