<template>
  <div>
    <yiye
      ref="myCanves"
      :canvasIdName="canvasIdName"
      :selectionArea="selectionArea"
      :clickFun="clickFun"
      @studentWorkTime="studentWorkTime"
    ></yiye>
    <scoreCorrection ref="scoreCorrection"></scoreCorrection>
    <doubleClick ref="doubleClick" :canvasIdName="canvasIdName"></doubleClick>
    <evaluate ref="evaluate"></evaluate>
  </div>
</template>

<script>
import yiye from './yiye.vue';
import scoreCorrection from './scoreCorrection.vue';
import doubleClick from './doubleClick.vue';
import evaluate from './evaluate.vue';
import { mapMutations } from 'vuex';
import { queryQuestionTreeList } from '../../api/Correct.js';
import { queryPlanUserMaxRecords } from '../../api/base.js';
import baseJs from '../../utils/base/base.js';
export default {
  name: 'Class',
  data() {
    return {
      cur_page: 0, //第几页
      intercept: null,
      userId: null, //用户id
      planId: null, //作业ID
      classId: null, //班级ID
      teachId: null, //老师Id
      teachLoginData: null,
      canvesWight: null, //canvse 宽
      pageId: null, //页面Id
      img: null, //渲染img
      setId: null,
      planData: null, //作业相关信息
      baseTopicInformation: null, //基本题目信息
      pageIds: null, //pagid数组
      img_list: null, //img数组
      teachlogin: null, //为了小程序加的登陆数据
    };
  },
  components: {
    yiye,
    scoreCorrection,
    doubleClick,
    evaluate,
  },
  watch: {},
  props: ['canvasIdName', 'selectionArea', 'clickFun'],
  mounted() {},
  methods: {
    studentWorkTime(e) {
      this.$emit('studentWorkTime', e);
      console.log('emit2', e);
    },
    postMsg(imgUrl) {
      uni.postMessage({
        data: {
          action: imgUrl,
        },
      });
    },
    getdialogscore() {
      console.log('显示双击');
      return this.$refs.doubleClick.dialogscore;
    },
    getresult() {
      return this.$refs.doubleClick.result;
    },
    //上一题下一题
    previousQuestion() {
      let data = {
        type: 'previous',
      };
      this.$refs.myCanves.switchWrongTopic(data);
      this.$refs.doubleClick.init();
    },
    nextQuestion() {
      let data = {
        type: 'next',
      };
      this.$refs.myCanves.switchWrongTopic(data);
      this.$refs.doubleClick.init();
    },
    // 初始化
    init() {
      this.cur_page = this.$parent.cur_page ? this.$parent.cur_page : 0;

      // 	if(!this.userId){
      // 	this.cur_page = Number(this.$store.state.current_page) != 0 ? Number(this.$store.state.current_page) : this.$parent.cur_page ? this.$parent.cur_page : 0
      // }else{
      // 	this.cur_page = this.$parent.cur_page
      // }
      this.teachlogin = this.$parent.teachlogin;
      this.teachLoginData = this.$parent.teachLoginData;
      this.userId = this.$parent.userId;
      this.planId = this.$parent.planId;
      this.classId = this.$parent.classId;
      this.planKind = this.$parent.planKind;
      this.teachId = this.$parent.teachId;
      this.intercept = this.$parent.intercept;
      this.canvesWight = this.$parent.canvesWight;
      this.pageId = this.$parent.pageIds[this.cur_page];
      this.img = this.$parent.img_list[this.cur_page];
      this.setId = this.$parent.setId;
      this.planData = this.$parent.planData;
      this.img_list = this.$parent.img_list;
      this.pageIds = this.$parent.pageIds;
      this.statues = this.$parent.statues;
      this.QueryQuestionTreeList();
    },
    // 切换 学生不用重新加载
    QueryQuestionTreeList() {
      queryQuestionTreeList({
        setId: this.setId,
      }).then((res) => {
        this.baseTopicInformation = res.data.result;
        this.$refs.myCanves.init();
      });
    },
    // 批改分数
    scoreCorrection() {
      this.$refs.scoreCorrection.init();
    },
    // 老师批改
    recorreted(answerAreaitem, item, result, dialogscore) {
      this.$refs.myCanves.recorreted(answerAreaitem, item, result, dialogscore);
    },
    doubleClick() {
      this.$refs.doubleClick.init(this.canvasIdName);
    },
    // 上一页
    previousPage() {
      this.pictureSize = this.$store.state.pictureSize;
      if (this.cur_page >= 1) {
        if (this.pictureSize == 'A3') {
          this.cur_page = this.cur_page - 2;
          // this.getCurrentPage(this.cur_page)
          this.pageId = this.pageIds[this.cur_page];
          this.img = this.img_list[this.cur_page];
          this.postMsg(this.img);
          this.$refs.myCanves.re_init_canvas();
        } else {
          this.cur_page = this.cur_page - 1;
          // this.getCurrentPage(this.cur_page)
          this.pageId = this.pageIds[this.cur_page];
          this.img = this.img_list[this.cur_page];
          this.postMsg(this.img);
          this.$refs.myCanves.re_init_canvas();
        }
      } else {
        this.$message('已经是第一页了');
      }
    },
    // 下一页
    nextPage() {
      this.pictureSize = this.$store.state.pictureSize;
      if (this.cur_page >= 0 && this.img_list.length >= this.cur_page + 2) {
        if (this.pictureSize == 'A3') {
          this.cur_page = this.cur_page + 2;
          // this.getCurrentPage(this.cur_page)
          if (this.pageIds[this.cur_page]) {
            this.pageId = this.pageIds[this.cur_page];
            this.img = this.img_list[this.cur_page];
            this.postMsg(this.img);
            this.$refs.myCanves.re_init_canvas();
          } else {
            this.cur_page = this.cur_page - 2;
          }
        } else {
          this.cur_page = this.cur_page + 1;
          // this.getCurrentPage(this.cur_page)
          this.pageId = this.pageIds[this.cur_page];
          this.img = this.img_list[this.cur_page];
          this.postMsg(this.img);
          this.$refs.myCanves.re_init_canvas();
        }
      } else {
        this.$message('已经是最后一页了');
      }
    },
    contentPage(data, type) {
      if (this.cur_page == data - 1) {
        // this.$refs.myCanves.scla_init_canvas()
      } else {
        if (this.pictureSize == 'A3') {
          this.cur_page = data - 2;
        } else {
          this.cur_page = data - 1;
        }
        this.pageId = this.pageIds[this.cur_page];
        this.img = this.img_list[this.cur_page];
        this.$refs.myCanves.re_init_canvas();
      }
    },
    //偏移
    deviation(data, stutes) {
      this.$refs.myCanves.deviation(data, stutes);
    },
    //保存偏移
    saveDeviation(stutes) {
      this.$refs.myCanves.saveDeviation(stutes);
    },
    // 评价
    evaluate() {
      this.$refs.evaluate.init();
    },
    // 重绘
    Repaint() {
      this.$refs.myCanves.Repaint();
    },
    ...mapMutations(['getCurrentPage']),
  },
};
</script>

<style></style>
