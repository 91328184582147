<template>
  <div id="tuozhuang" class="displayFlex justifyContentCenter">
    <!-- :style="{'width': whetherWrong&&pictureSize=='A3'&&pageList?canvesWight/2+'px':canvesWight+'px'}" -->
    <!-- <div v-if="!$store.state.rollShow" :style="{'width': whetherWrong&&pictureSize=='A3'&&pageList?canvesWight/2+'px':canvesWight+'px'}" class="overflowHidden"></div> -->
    <div
      v-if="!$store.state.rollShow"
      :style="{ width: canvesWight }"
      class="overflowHidden"
    >
      <!-- 情况不对就把:style="{'width': canvesWight ,'height':canvesHeight}"删掉，上下一共两个 -->
      <!-- <div v-if="!$store.state.rollShow" class="overflowHidden" > -->
      <!-- :height="(initialValueEndY-initialValueStartY)*multiple"  j就只是第一个和第三个canvas的高度，-->
      <canvas
        :id="canvasIdName"
        ref="myCanves"
        @mousewheel.prevent="rollImg1"
        v-if="intercept && initialValueStartY"
        :width="canvesWight"
        @dblclick="canvasclicks()"
        :height="(initialValueEndY - initialValueStartY) * multiple"
        @click="canvasclicks()"
        @touchstart="touchStart"
        @touchmove="touchmove"
        :style="{
          transform:
            whetherWrong &&
            pictureSize == 'A3' &&
            pageList &&
            pageList[pageListIndex].pageNo % 2 != 1
              ? 'translateX(-50%)'
              : 'translateX(0px)',
        }"
      ></canvas>
      <canvas
        :id="canvasIdName"
        v-else
        :width="canvesWight"
        :height="canvesHeight"
        @dblclick="canvasclicks()"
        @click="canvasclicks()"
        ref="myCanves"
        @mousewheel.prevent="rollImg1"
        @touchstart="touchStart"
        @touchmove="touchmove"
      ></canvas>
    </div>
    <!-- :style="{'width': whetherWrong&&pictureSize=='A3'&&pageList?canvesWight/2+'px':canvesWight+'px'}"  -->
    <div v-else class="overflowHidden" :style="{ width: canvesWight }">
      <!-- :height="(initialValueEndY-initialValueStartY)*multiple"  -->
      <canvas
        :id="canvasIdName"
        :height="(initialValueEndY - initialValueStartY) * multiple"
        ref="myCanves"
        v-if="intercept && initialValueStartY"
        :width="canvesWight"
        @dblclick="canvasclicks()"
        @click="canvasclicks()"
        @touchstart="touchStart"
        @touchmove="touchmove"
        :style="{
          transform:
            whetherWrong &&
            pictureSize == 'A3' &&
            pageList &&
            pageList[pageListIndex].pageNo % 2 != 1
              ? 'translateX(-50%)'
              : 'translateX(0px)',
        }"
      ></canvas>
      <canvas
        :id="canvasIdName"
        v-else
        :width="canvesWight"
        :height="canvesHeight"
        @dblclick="canvasclicks()"
        @click="canvasclicks()"
        ref="myCanves"
        @touchstart="touchStart"
        @touchmove="touchmove"
      ></canvas>
    </div>
  </div>
</template>

<script>
import { showLoading, hideLoading } from '../../utils/Loading';
import {
  getdotsv3,
  getStudentResult,
  Recorreted,
  getCorrectionFromPlan,
  auto_correctv1,
  batch_auxiliary_correction,
  addUserExtendAnswerList,
  queryPlanUserJoinDetail,
  okCorrected,
  needCorrect,
  queryPlanUserJoinRecords,
  submitEvaluation,
  updateSign,
  queryMacSingList,
  gotEvaluation,
  queryNameCardSelectList,
  queryPlanUserAutoJoinRecords,
  wrongAnswerCorreted,
} from '../../api/Correct.js';
import {
  queryListByDictValue,
  queryPlanUserMaxRecords,
  queryCorrectUserIdByMac,
  baseQueryDetailById,
} from '../../api/base.js';
import { queryClassRankList } from '../../api/Study.js';
import Correction from '../../utils/mycanvesjs/Correction.js';
import baseJs from '../../utils/base/base.js';
import { WebSocketFunc } from '../../utils/src_utils_socket.js';
export default {
  name: 'Class',
  data() {
    return {
      tagNumber: 0,
      pageListIndex: 0, //一题几页下标
      selectmintopicindex: null, //显示一题的大题序号
      childrenListindex: null, //显示一题的小题序号
      whetherWrong: null, //是否根据错题
      pageList: null, //这道题有几页
      baseTopicAreaList: null, //一道题的答题区域
      intercept: null, //显示一道题
      initialValueStartY: null, //开始Y点坐标
      initialValueEndY: null, //开始Y点坐标
      // initialValueStartY:0,//开始Y点坐标
      canvesWight: null, //canvse 宽
      canvesHeight: null, //canvse 高
      pictureSize: '', //A3还是A4
      multiple: null, //canvse放大倍数
      userId: null, //用户id
      teachId: null, //老师Id
      teachLoginData: null, //登录信息
      classId: null, //班级Id
      planId: null, //作业Id
      planData: null, //题集相关信息
      img: null, //渲染img
      studentData: {
        PenMac: null,
        deviationX: null,
        deviationY: null,
      },
      teachData: {
        PenMac: null,
        deviationX: null,
        deviationY: null,
      },
      totalScore: null, //总分
      zoom: 100,
      pageId: null, //pageId
      cur_page: null, //第几页
      baseTopicInformation: '', //题目基本数据
      recorretedData: {}, //老师批改数据
      dialogscoreshow: false, //
      dialogscore: null, //分数
      enlargeN: 1, //放大倍数
      dotsDrawCanshuEr: null, //A3第二页数据
      dotsDrawCanshu: null, //第一页数据
      teachDotsDrawCanshuEr: null, //老师/A3第二页数据
      teachDotsDrawCanshu: null, //老师第一页数据
      zhangsanIndex: 1,
      currentIndex: 1,
      teachZhangsanIndex: 1,
      planKind: '作业',
      stageKind: '课中',
      teachanswertype: '作业本',
      originScale: 1,
      showEndTime: 0, //学生笔迹要显示的结束时间
      showBeginTime: 111675906464795, //学生笔迹要显示的开始时间:为什么取大值，因为传过来两个数据要取小，这个最大其实能判断
      a3InGetDotsNum: 0,
    };
  },
  components: {},
  watch: {
    '$store.state.whetherWrong'(val) {
      if (!val) {
        this.filterDuplicateRemovalShow = false;
      }
    },
    originScale(val) {
      if (val) {
        let Scale = 'teachplanId' + this.planId;
        localStorage.setItem(Scale, JSON.stringify(val));
        this.scla_init_canvas();
      }
    },
    userId(val) {
      if (val) {
        this.QueryPlanUserMaxRecords();
      }
    },
    'studentData.PenMac'(val) {
      this.getWebSocket();
    },
    'teachData.PenMac'(val) {
      this.teachGetWebSocket();
    },
    enlargeN(val) {
      this.init();
    },
  },
  props: ['canvasIdName', 'selectionArea', 'clickFun'],
  mounted() {},
  destroyed() {
    this.getWebSocketclose();
  },
  methods: {
    // 滚动放大
    rollImg1() {
      let that = this;
      // var zoom = parseInt(this.$refs.myCanves.style.zoom) || 100;
      // zoom += event.wheelDelta / 12;
      // if(zoom > 0 )
      // this.$refs.myCanves.style.zoom = zoom + "%";
      if (!this.$store.state.rollShow) {
        this.zoom = parseInt(this.zoom) || 100;
        this.zoom += event.wheelDelta / 12;
        if (this.zoom / 100 < 0.2) {
          this.zoom = 20;
          this.originScale = 0.5;
        } else if (this.zoom / 100 > 5) {
          this.zoom = 500;
          this.originScale = 2.5;
        } else {
          this.originScale = this.zoom / 100;
        }
        // if (that.planData.planKind == "笔记" || that.planData.planKind == "微课") {
        this.init();
        // }

        // if (that.planData.planKind == "笔记" || that.planData.planKind == "微课") {
        // 	let text = that.cur_page + 1
        // 	text = "第" + text + "页"
        // 	that.draw_score(that.canvesWight / 2, 10, text)
        // }

        return false;
      }
    },
    touchStart(evt) {
      // alert(11)
      if (evt.touches.length == 1) {
        this.touchStartData = null;
      } else if (evt.touches.length == 2) {
        this.touchStartData = evt.touches;
      }
    },
    touchmove(evt) {
      let originScale;
      if (evt.touches.length == 1) {
        this.touchmoveData = null;
      } else if (evt.touches.length == 2) {
        this.touchmoveData = evt.touches;
        if (this.touchmoveData && this.touchStartData) {
          if (this.originScale > 5) {
            originScale = 5;
          } else if (this.originScale < 0.2) {
            originScale = 0.2;
          } else {
            originScale =
              (this.originScale *
                this.getDistance(
                  this.touchmoveData[0],
                  this.touchmoveData[1]
                )) /
              this.getDistance(this.touchStartData[0], this.touchStartData[1]);
          }
          if (this.iTime) {
            clearTimeout(this.iTime);
          }
          this.iTime = setTimeout(() => {
            this.originScale = originScale;
          }, 100);
        }
      }
    },
    getDistance(p1, p2) {
      //触屏前位置-触屏后位置
      var x = p2.pageX - p1.pageX,
        y = p2.pageY - p1.pageY;
      //返回平方根  滑动的直线距离
      return Math.sqrt(x * x + y * y);
    },
    BaseQueryDetailById(img1, canvas_id) {
      let that = this;
      let canshu = this.$Download.getQuestionSet(
        this.planData.resourceRelationList,
        'questionSet'
      ).relationId;
      baseQueryDetailById('setId=' + canshu).then((res) => {
        this.BaseQueryDetailByIdData = res.data.result;
        if (
          this.BaseQueryDetailByIdData.epenCodeList[this.cur_page].width &&
          this.BaseQueryDetailByIdData.epenCodeList[this.cur_page].height
        ) {
          this.canvesHeight =
            (this.BaseQueryDetailByIdData.epenCodeList[this.cur_page].height /
              this.BaseQueryDetailByIdData.epenCodeList[this.cur_page].width) *
            this.canvesWight;
        } else {
          this.canvesHeight = (img1.height / img1.width) * this.canvesWight;
        }
        this.RenderImg(
          img1.src,
          this.canvesWight,
          this.canvesHeight,
          this.canvasIdName,
          canvas_id
        );
        if (
          that.planData.planKind == '笔记' ||
          that.planData.planKind == '微课'
        ) {
          let text = that.cur_page + 1;
          text = '第' + text + '页';
          that.draw_score(that.canvesWight / 2, 10, text);
        }
      });
    },
    clia() {
      let canshu = {
        pageId: 20615,
        planId: 7323,
        questionId: 47289,
        result: 1,
        score: 0,
        serial: 1,
        similarity: 1,
        stuId: 2274,
      };
      this.ReorganizeAata(canshu);
    },
    //初始化
    init() {
      this.userId = null;
      this.$nextTick(() => {
        this.whetherWrong = this.$store.state.whetherWrong;
        this.userId = this.$parent.userId;
        this.planId = this.$parent.planId;
        this.classId = this.$parent.classId;
        this.planData = this.$parent.planData;
        this.teachLoginData = this.$parent.teachLoginData;
        this.teachId = this.$parent.teachId;
        this.cur_page = this.$parent.cur_page;
        this.img = this.$parent.img;
        this.pageId = this.$parent.pageId;
        this.pageIds = this.$parent.pageIds;
        this.intercept = this.$parent.intercept;
        this.canvesWight = this.$parent.canvesWight * this.enlargeN;
        this.statues = this.$parent.statues;
        let Scale = 'teachplanId' + this.planId;
        if (localStorage.getItem(Scale)) {
          this.originScale = JSON.parse(localStorage.getItem(Scale));
        }
        this.baseTopicInformation = this.$parent.baseTopicInformation;
        if (
          this.intercept &&
          this.baseTopicInformation &&
          this.baseTopicInformation.length > 0
        ) {
          this.getQuestionsIndex(
            JSON.parse(localStorage.getItem('selectmintopicindex')),
            JSON.parse(localStorage.getItem('childrenListindex'))
          );
        } else {
          this.initialValueStartY = null;
        }
        this.init_canvas(this.img);
        this.queryclassranklist();
      });
    },
    frontSwitchWrongTopic(data) {
      this.pageListIndex = 0;
      this.$store.commit('getSubmitCheckStatuShow', false);
      if (data.type == 'int') {
        this.switchWrongTopicIndex = 0;
      } else if (data.type == 'next') {
        if (this.switchWrongTopicIndex < this.PlanUserMaxRecords.length - 1) {
          this.switchWrongTopicIndex = this.switchWrongTopicIndex + 1;
        } else {
          console.log('已经批改完成');
          this.$store.commit('getSubmitCheckStatuShow', true);
          return;
        }
      } else if (data.type == 'previous') {
        if (this.switchWrongTopicIndex > 0) {
          this.switchWrongTopicIndex = this.switchWrongTopicIndex - 1;
        } else {
          console.log('没有上一题');
          return;
        }
      }
    },
    //切换错题
    switchWrongTopic(data) {
      let that = this;
      if (data.type == 'int') {
        this.switchWrongTopicIndex = 0;
        this.pageListIndex = 0;
      } else if (data.type == 'next') {
        if (
          this.pageList.length > 1 &&
          this.pageListIndex < this.pageList.length - 1
        ) {
          this.pageListIndex = this.pageListIndex + 1;
        } else {
          this.frontSwitchWrongTopic(data);
        }
      } else if (data.type == 'previous') {
        if (this.pageList.length > 1 && this.pageListIndex > 0) {
          this.pageListIndex = this.pageListIndex - 1;
        } else {
          this.frontSwitchWrongTopic(data);
        }
      }
      // console.log(this.PlanUserMaxRecords,this.switchWrongTopicIndex);  4-[0]-[0] 5-[3]-[0]    //答题结果列表，错误空索引
      this.baseTopicInformation.forEach((item, index) => {
        if (item.childrenList && item.childrenList.length > 0) {
          item.childrenList.forEach((childrenItem, childrenIndex) => {
            if (
              this.PlanUserMaxRecords[this.switchWrongTopicIndex].sub_id ==
              childrenItem.id
            ) {
              this.selectmintopicindex = index;
              this.childrenListindex = childrenIndex;
            }
          });
        } else {
          if (
            this.PlanUserMaxRecords[this.switchWrongTopicIndex].sub_id ==
            item.id
          ) {
            console.log(that.PlanUserMaxRecords, that.switchWrongTopicIndex);
            this.selectmintopicindex = index; //拿到错题的大题的index
            this.childrenListindex = null; //因为该大题没有小题  所以设置为null
          }
        }
      });
      let serial = this.PlanUserMaxRecords[this.switchWrongTopicIndex].serial;
      let answerAreaList =
        this.childrenListindex || this.childrenListindex == 0
          ? this.baseTopicInformation[this.selectmintopicindex].childrenList[
              this.childrenListindex
            ].answerAreaList
          : this.baseTopicInformation[this.selectmintopicindex].answerAreaList;
      let answerAreaitem;
      for (let i = 0; i < answerAreaList.length; i++) {
        if (answerAreaList[i].serialAnswer == serial) {
          answerAreaitem = answerAreaList[i];
        }
      }
      // let serial=this.PlanUserMaxRecords[this.switchWrongTopicIndex].serial-1
      // console.log(serial,"serial",this.baseTopicInformation[this.selectmintopicindex],this.childrenListindex||this.childrenListindex==0?this.baseTopicInformation[this.selectmintopicindex].childrenList[this.childrenListindex].answerAreaList[serial]:this.baseTopicInformation[this.selectmintopicindex].answerAreaList[serial])
      let dataRecorretedData = {
        // answerAreaitem:this.childrenListindex||this.childrenListindex==0?this.baseTopicInformation[this.selectmintopicindex].childrenList[this.childrenListindex].answerAreaList[serial]:this.baseTopicInformation[this.selectmintopicindex].answerAreaList[serial],
        answerAreaitem: answerAreaitem,
        item:
          this.childrenListindex || this.childrenListindex == 0
            ? this.baseTopicInformation[this.selectmintopicindex].childrenList[
                this.childrenListindex
              ]
            : this.baseTopicInformation[this.selectmintopicindex],
        parentId: baseJs.orgChildIdFindParents(
          this.childrenListindex || this.childrenListindex == 0
            ? this.baseTopicInformation[this.selectmintopicindex].childrenList[
                this.childrenListindex
              ]
            : this.baseTopicInformation[this.selectmintopicindex].id,
          this.baseTopicInformation
        ),
      };
      this.$store.commit('getRecorretedDatas', dataRecorretedData);
      this.getQuestionsIndex(this.selectmintopicindex, this.childrenListindex);
      this.getInitialValue();
      this.switch_init_canvas();
      // console.log(this.baseTopicAreaList,"this.baseTopicAreaList")
      let pageListAreaList = JSON.parse(JSON.stringify(this.baseTopicAreaList));
      let pageList = pageListAreaList.sort((a, b) => a.pageNo - b.pageNo);
      this.pageList = baseJs.recombinationCanshu(pageList, 'pageNo');
      // console.log(this.pageList,'this.pageList')
      if (this.pictureSize == 'A3') {
        this.$parent.contentPage(
          this.pageList[this.pageListIndex].pageNo % 2 == 1
            ? this.pageList[this.pageListIndex].pageNo
            : this.pageList[this.pageListIndex].pageNo - 1,
          data
        );
      } else {
        this.$parent.contentPage(
          this.pageList[this.pageListIndex].pageNo,
          data
        );
      }
      // this.$parent.nextPage()
    },
    //学生成绩
    queryclassranklist() {
      if (this.planData.planKind == '测验') {
        let data = {
          parameters: {
            classId: this.classId,
            planId: this.planId,
            userId: this.userId,
          },
        };
        queryClassRankList(data).then((res) => {
          this.totalScore = res.data.result[0].totalScore;
          if (this.totalScore) {
            this.draw_score(
              (120 * this.multiple) / 10,
              (40 * this.multiple) / 10,
              '总分:' + this.totalScore + '分'
            );
          } else {
            this.draw_score(
              (120 * this.multiple) / 10,
              (40 * this.multiple) / 10,
              '总分:0分'
            );
          }
        });
      } else {
        this.totalScore = null;
      }
    },
    // 获取题目下标
    getQuestionsIndex(selectmintopicindex, childrenListindex) {
      this.selectmintopicindex = selectmintopicindex;
      this.childrenListindex = childrenListindex;
      this.baseTopic =
        this.baseTopicInformation[this.selectmintopicindex].childrenList &&
        this.baseTopicInformation[this.selectmintopicindex].childrenList
          .length > 0 &&
        (this.childrenListindex || this.childrenListindex == 0)
          ? this.baseTopicInformation[this.selectmintopicindex].childrenList[
              this.childrenListindex
            ]
          : this.baseTopicInformation[this.selectmintopicindex];
      let baseTopic = JSON.parse(JSON.stringify(this.baseTopic));
      if (this.whetherWrong) {
        if (this.PlanUserMaxRecords && this.PlanUserMaxRecords.length > 0) {
          // console.log(baseTopic,"baseTopic")
          if (
            baseTopic.questionOutVo.featureCode == '1100001' ||
            baseTopic.questionOutVo.featureCode == '1200001'
          ) {
            this.baseTopicAreaList = baseTopic.answerAreaList.concat(
              baseTopic.questionAreaList
            );
          } else {
            let serial =
              this.PlanUserMaxRecords[this.switchWrongTopicIndex].serial - 1;
            this.baseTopicAreaList = [baseTopic.answerAreaList[serial]];
          }
        } else {
          return;
        }
      } else {
        this.baseTopicAreaList = baseTopic.answerAreaList.concat(
          baseTopic.questionAreaList
        );
      }
    },
    // initialValue 获取初始值 一道题
    getInitialValue() {
      if (this.baseTopicAreaList && this.baseTopicAreaList.length > 0) {
        let pageListAreaList = JSON.parse(
          JSON.stringify(this.baseTopicAreaList)
        );
        let AS1AreaList = JSON.parse(JSON.stringify(this.baseTopicAreaList));
        let AS2AreaList = JSON.parse(JSON.stringify(this.baseTopicAreaList));
        let pageList = pageListAreaList.sort((a, b) => a.pageNo - b.pageNo);
        this.pageList = baseJs.recombinationCanshu(pageList, 'pageNo');
        let AS1;
        let AS2;
        if (this.pictureSize == 'A3') {
          AS1 = AS1AreaList.sort((a, b) => a.startY - b.startY).filter(
            (vla) => {
              return (
                vla.pageNo == this.cur_page + 1 ||
                vla.pageNo == this.cur_page + 2
              );
            }
          );
          AS2 = AS2AreaList.sort((a, b) => a.endY - b.endY).filter((vla) => {
            return (
              vla.pageNo == this.cur_page + 1 || vla.pageNo == this.cur_page + 2
            );
          });
        } else {
          AS1 = AS1AreaList.sort((a, b) => a.startY - b.startY).filter(
            (vla) => {
              return vla.pageNo == this.cur_page + 1;
            }
          );
          AS2 = AS2AreaList.sort((a, b) => a.endY - b.endY).filter((vla) => {
            return vla.pageNo == this.cur_page + 1;
          });
        }
        if (AS1[0]) {
          this.initialValueStartY = AS1[0].startY;
          this.initialValueEndY = AS2[AS2.length - 1].endY + 1;
        } else {
          this.initialValueStartY = null;
        }
      }
    },
    //重新渲染canvse
    re_init_canvas() {
      this.img = this.$parent.img;
      this.canvesWight = this.$parent.canvesWight * this.enlargeN;
      this.cur_page = this.$parent.cur_page;
      this.pageId = this.$parent.pageId;
      this.queryclassranklist();
      this.init_canvas(this.img, true);
    },
    //偏移
    deviation(data, stutes) {
      if (data == 'right') {
        this[stutes].deviationX =
          (this[stutes].deviationX * 10 + 0.1 * 10) / 10;
      } else if (data == 'left') {
        this[stutes].deviationX =
          (this[stutes].deviationX * 10 - 0.1 * 10) / 10;
      } else if (data == 'top') {
        this[stutes].deviationY =
          (this[stutes].deviationY * 10 - 0.1 * 10) / 10;
      } else if (data == 'bottom') {
        this[stutes].deviationY =
          (this[stutes].deviationY * 10 + 0.1 * 10) / 10;
      }
      if (stutes == 'teachData') {
        if (this.pictureSize == 'A3') {
          this.dots_draw(this.teachDotsDrawCanshuEr, stutes);
        }
        this.dots_draw(this.teachDotsDrawCanshu, stutes);
      } else {
        if (this.pictureSize == 'A3') {
          this.dots_draw(this.dotsDrawCanshuEr, stutes);
        }
        //滚动调取
        this.dots_draw(this.dotsDrawCanshu, stutes);
      }
    },
    // 保存偏移
    saveDeviation(stutes) {
      let data = {
        id: stutes == 'studentData' ? this.userId : this.teachId,
        sign: JSON.stringify({
          x: this[stutes].deviationX,
          y: this[stutes].deviationY,
        }),
      };
      updateSign(data).then((res) => {
        this.$message('设置偏移成功');
      });
    },
    //初始化canvse
    init_canvas: function(img_src, canvas_id, show) {
      //这就是吧图片放进canvse
      const canvas = document.getElementById(this.canvasIdName);
      this.ctx = canvas.getContext('2d');
      var img1 = new Image();
      img1.src = img_src;
      img1.onload = () => {
        this.pictureSize = Correction.JudgmentA3A4(img1);
        this.$store.commit('getPictureSize', this.pictureSize);
        this.canvesWight = this.canvesWight * this.originScale;
        this.multiple = ((this.canvesWight * 10) / img1.width) * 2.032;
        if (this.intercept) {
          this.getInitialValue();
        }
        this.BaseQueryDetailById(img1, canvas_id);
        // this.canvesHeight = img1.height / img1.width * this.canvesWight
        // this.RenderImg(img1.src, this.canvesWight, this.canvesHeight, this.canvasIdName, canvas_id)
        // if(this.planData.planKind=="笔记"||this.planData.planKind=="微课"){
        // 	let text=this.cur_page+1
        // 	text="第"+text+"页"
        // 	this.draw_score(this.canvesWight/2,10,text)
        // }
      };
    },
    //放大逻辑
    scla_init_canvas() {
      // alert(123)
      const canvas = document.getElementById(this.canvasIdName);
      this.ctx = canvas.getContext('2d');
      var img1 = new Image();
      img1.src = this.img;
      img1.onload = () => {
        this.pictureSize = Correction.JudgmentA3A4(img1);
        this.$store.commit('getPictureSize', this.pictureSize);
        this.canvesWight = this.$parent.canvesWight * this.enlargeN;
        this.canvesWight = this.canvesWight * this.originScale;
        this.multiple = ((this.canvesWight * 10) / img1.width) * 2.032;
        this.canvesHeight = (img1.height / img1.width) * this.canvesWight;
        this.imgCanves(
          img1.src,
          this.canvesWight,
          this.canvesHeight,
          this.canvasIdName,
          true
        );
      };
    },
    //切换题目初始化
    switch_init_canvas() {
      const canvas = document.getElementById(this.canvasIdName);
      this.ctx = canvas.getContext('2d');
      var img1 = new Image();
      img1.src = this.img;
      this.getInitialValue();
      img1.onload = () => {
        this.pictureSize = Correction.JudgmentA3A4(img1);
        this.$store.commit('getPictureSize', this.pictureSize);
        this.canvesWight = this.$parent.canvesWight * this.enlargeN;
        this.canvesWight = this.canvesWight * this.originScale;
        this.multiple = ((this.canvesWight * 10) / img1.width) * 2.032;
        this.canvesHeight = (img1.height / img1.width) * this.canvesWight;
        this.imgCanves(
          img1.src,
          this.canvesWight,
          this.canvesHeight,
          this.canvasIdName,
          true
        );
      };
    },
    imgCanves(imgUrl, canvesWight, canvesHeight, className, show) {
      // console.log(this.pageList[this.pageListIndex].pageNo)

      $('#' + className)
        .drawImage({
          source: imgUrl,
          layer: true,
          name: '1111111',
          // x: this.whetherWrong&&this.pictureSize=='A3'&&this.pageList[this.pageListIndex].pageNo%2!=1?-(canvesWight/2):0,
          x: 0,
          y: -(this.initialValueStartY * this.multiple),
          width: canvesWight,
          height: canvesHeight,
          fromCenter: false,
          shadowColor: '#222',
          shadowBlur: 3,
        })
        .drawLayers();
      this.$nextTick(() => {
        let cnashu = JSON.parse(JSON.stringify(this.baseTopicInformation));
        let recombination = baseJs.recombinationParent(cnashu);
        this.RenderingAMarqueeFun(recombination, 'result');
        if (show) {
          this.RenderingAMarqueeFun(this.baseTopicInformation);
          //批改结果重组
          let cnashu = JSON.parse(JSON.stringify(this.baseTopicInformation));
          let recombination = baseJs.recombinationParent(cnashu);
          this.RenderingAMarqueeFun(recombination, 'result');
          this.show_dots_draw(this.dotsDrawCanshu, canvesWight, 'studentData');
          if (this.pictureSize == 'A3') {
            this.show_dots_draw(
              this.dotsDrawCanshuEr,
              canvesWight,
              'studentData'
            );
            if (this.teachDotsDrawCanshuEr) {
              this.show_dots_draw(
                this.teachDotsDrawCanshuEr,
                canvesWight,
                'teachData'
              );
            }
          }
          if (this.teachDotsDrawCanshu) {
            this.show_dots_draw(
              this.teachDotsDrawCanshu,
              canvesWight,
              'teachData'
            );
          }
          if (this.planData.planKind == '测验') {
            if (this.totalScore) {
              this.draw_score(
                (120 * this.multiple) / 10,
                (40 * this.multiple) / 10,
                '总分:' + this.totalScore + '分'
              );
            } else {
              this.draw_score(
                (120 * this.multiple) / 10,
                (40 * this.multiple) / 10,
                '总分:0分'
              );
            }
          }
        }
      });
    },
    //请求渲染点数据
    show_dots_draw(DrawCanshu, canvesWight, states) {
      DrawCanshu && DrawCanshu.n ? (DrawCanshu.n = this.multiple) : '';
      DrawCanshu && DrawCanshu.width ? (DrawCanshu.width = canvesWight) : '';
      DrawCanshu ? this.dots_draw(DrawCanshu, states) : '';
    },
    // img渲染
    RenderImg(imgUrl, canvesWight, canvesHeight, className, canvas_id) {
      this.$nextTick(() => {
        this.imgCanves(imgUrl, canvesWight, canvesHeight, className);
        if (this.userId) {
          if (canvas_id) {
            if (!this.selectionArea) {
              this.RenderingAMarqueeFun(this.baseTopicInformation);
              //批改结果重组
              let cnashu = JSON.parse(
                JSON.stringify(this.baseTopicInformation)
              );
              let recombination = baseJs.recombinationParent(cnashu);
              this.RenderingAMarqueeFun(recombination, 'result');
            }
            this.getDotsv3(
              '学生',
              this.planData.beginTime,
              this.planData.endTime
            );
            if (this.pictureSize == 'A3') {
              this.getDotsv3(
                '学生',
                this.planData.beginTime,
                this.planData.endTime,
                '二'
              );
            }
            this.QueryNameCardSelectList();
          } else {
            if (!this.selectionArea) {
              this.RenderingAMarquee();
            }
            this.QueryMacSingList('学生', this.userId);
            this.QueryMacSingList('老师', this.teachId);
          }
        }
      });
    },
    // 获取pen 和偏移坐标
    QueryMacSingList(type, id) {
      let data = {
        userId: id,
      };
      if ((this.planData.roomId || this.planData.num) && type == '学生') {
        data.beginTime = this.planData.beginTime;
        data.endTime = this.planData.endTime;
      }
      queryMacSingList(data).then((res) => {
        if (res.data.result && res.data.result.length > 0) {
          let deviation = JSON.parse(res.data.result[0].sign);
          let attribute = type == '学生' ? 'studentData' : 'teachData';
          this[attribute].PenMac = res.data.result[0].penMac;
          this[attribute].deviationX =
            deviation && deviation.x ? deviation.x : 0;
          this[attribute].deviationY =
            deviation && deviation.y ? deviation.y : 0;
          if (type == '学生') {
            this.getDotsv3(
              type,
              this.planData.beginTime,
              this.planData.endTime
            );
            if (this.pictureSize == 'A3') {
              this.getDotsv3(
                type,
                this.planData.beginTime,
                this.planData.endTime,
                '二'
              );
            }
          } else {
            this.QueryNameCardSelectList();
          }
        } else {
          let attribute = type == '学生' ? 'studentData' : 'teachData';
          this[attribute].deviationX = 0;
          this[attribute].deviationY = 0;
        }
      });
    },
    //点数据获取
    getDotsv3(type, beginTime, endTime, stutes) {
      let _this = this;
      let attribute = type == '学生' ? 'studentData' : 'teachData';
      let data = {
        data: {
          beginTime: beginTime,
          endTime: endTime,
          penMac: this[attribute].PenMac,
          startPageId: stutes == '二' ? this.pageId + 1 : this.pageId,
          endPageId: stutes == '二' ? this.pageId + 1 : this.pageId,
          startX: 0,
          startY: this.whetherWrong
            ? 0
            : this.initialValueStartY
            ? this.initialValueStartY
            : 0,
          endX: 104,
          endY: this.whetherWrong
            ? 147.16141732283464
            : this.initialValueStartY
            ? this.initialValueEndY
            : 147.16141732283464,
          removeDots: JSON.parse(this.$store.state.deleteSymbolShow) ? 0 : 1,
        },
      };
      // console.log(data);
      // showLoading()
      getdotsv3(data)
        .then((res) => {
          //  a3纸会调用2次  所以要拿2次中最大的endTime和最小的beginTIme
          if (this.pictureSize == 'A3') {
            this.a3InGetDotsNum++; //记录a3纸第几次进入
            if (res.data.endTime > this.showEndTime) {
              this.showEndTime = res.data.endTime;
            }
            if (res.data.beginTime < this.showBeginTime) {
              this.showBeginTime = res.data.beginTime;
            }
            console.log('返回的时间', res.data.endTime, res.data.beginTime);
            console.log('showEndTime::', this.showEndTime, this.showBeginTime);
            //第二次才传值给学生列表显示学生做题时间
            if (this.a3InGetDotsNum == 2) {
              this.$emit(
                'studentWorkTime',
                ((this.showEndTime - this.showBeginTime) / 1000).toFixed(2)
              );
              this.a3InGetDotsNum = 0; //重置一下
            }
          } else {
            console.log('9090');
            this.$emit(
              'studentWorkTime',
              ((res.data.endTime - res.data.beginTime) / 1000).toFixed(2)
            );
          }
          let canshu = {
            data: res.data.Stroke,
            cxt: this.ctx,
            n: this.multiple,
            pictureSize: this.pictureSize,
            width: this.canvesWight,
            stutes: stutes,
            height: this.canvesHeight,
          };
          if (type == '学生') {
            if (this.pictureSize == 'A3' && stutes == '二') {
              this.dotsDrawCanshuEr = canshu;
            } else {
              this.dotsDrawCanshu = canshu;
            }
          } else {
            if (this.pictureSize == 'A3' && stutes == '二') {
              this.teachDotsDrawCanshuEr = canshu;
            } else {
              this.teachDotsDrawCanshu = canshu;
            }
          }
          if (this.tagNumber == 0) {
            this.dots_draw(canshu, attribute);
          } else {
            this.Repaints();
          }
        })
        .catch((res) => {
          // hideLoading()
        });
    },
    // 点数据渲染 历史
    dots_draw(canshu, attribute) {
      canshu.deviationX = this[attribute].deviationX;
      canshu.deviationY = this[attribute].deviationY;
      canshu.pageId = this.pageId;
      if (attribute == 'studentData') {
        this.zhangsanIndex = this.zhangsanIndex + 1;
      } else {
        this.teachZhangsanIndex = this.teachZhangsanIndex + 1;
      }
      canshu.nextPageId = this.pageIds[this.cur_page + 1];
      canshu.initialValueStartY = this.initialValueStartY;

      $('#' + this.canvasIdName)
        .removeLayer('zhangsan')
        .drawLayers();
      $('#' + this.canvasIdName).draw({
        rounded: true, //这是什么属性
        layer: true,
        name:
          attribute == 'studentData'
            ? 'zhangsan' + this.zhangsanIndex
            : 'teachData' + this.teachZhangsanIndex,
        fn: () => {
          this.RenderColor(attribute);
          Correction.dotsDraw(canshu);
        },
      });
    },
    // 点数据渲染 实时 studentData
    real_dots_draw(canshu, attribute) {
      canshu.deviationX = this[attribute].deviationX;
      canshu.deviationY = this[attribute].deviationY;
      canshu.pageId = this.pageId;
      canshu.nextPageId = this.pageIds[this.cur_page + 1];
      this.currentIndex = this.currentIndex + 1;
      // $("#" + this.canvasIdName).removeLayer('zhangsan').drawLayers()
      canshu.initialValueStartY = this.initialValueStartY;
      $('#' + this.canvasIdName)
        .draw({
          layer: true,
          name: 'current' + this.currentIndex,
          fn: () => {
            this.RenderColor(attribute);
            Correction.actualTimedotsDraw(canshu);
          },
        })
        .drawLayers();
    },
    // 重绘
    async Repaint() {
      this.tagNumber = 1;
      this.RepaintShow = false;
      this.reRepaintShow = true;
      this.RenderColor('studentData');
      //重绘从这里调用
      await this.getDotsv3(
        '学生',
        this.planData.beginTime,
        this.planData.endTime
      );
      // this.zhangsanIndex--
      // this.QueryNameCardSelectList()

      // this.Repaints()
    },
    Repaints() {
      let baseTopic =
        this.baseTopicInformation[this.selectmintopicindex].childrenList &&
        this.baseTopicInformation[this.selectmintopicindex].childrenList
          .length > 0
          ? this.baseTopicInformation[this.selectmintopicindex].childrenList[
              this.childrenListindex
            ]
          : this.baseTopicInformation[this.selectmintopicindex];
      baseTopic.answerAreaList.forEach((item) => {
        var startX = item.startX * this.multiple;
        var endX = item.endX * this.multiple;
        var startY = item.startY * this.multiple;
        var endY = item.endY * this.multiple;
      });
      // $("#" + this.canvasIdName).removeLayer('zhangsan' + this.zhangsanIndex).drawLayers()
      $('#' + this.canvasIdName)
        .removeLayer('zhangsan' + this.zhangsanIndex)
        .drawLayers();
      if (this.currentIndex != 1) {
        for (let i = 2; i <= this.currentIndex; i++) {
          $('#' + this.canvasIdName)
            .removeLayer('current' + i)
            .drawLayers();
        }
      }

      $('#' + this.canvasIdName)
        .removeLayer('zhangsan')
        .drawLayers();
      this.dotsDrawCanshu.deviationX = this.studentData.deviationX;
      this.dotsDrawCanshu.deviationY = this.studentData.deviationY;
      this.dotsDrawCanshu.pageId = this.pageId;
      this.dotsDrawCanshu.nextPageId = this.pageIds[this.cur_page + 1];
      // this.dotsDrawCanshu.interval = 150
      this.dotsDrawCanshu.interval = this.$store.state.dotDrawSpeed;
      //
      this.dotsDrawCanshu.initialValueStartY = this.initialValueStartY;
      if (this.pictureSize == 'A3') {
        this.dotsDrawCanshu.data = this.dotsDrawCanshu.data.concat(
          this.dotsDrawCanshuEr.data
        );
      }
      $('#' + this.canvasIdName).draw({
        layer: true,
        name: 'zhangsan', //画布上的图层名称(必须是唯一的)
        // 绘制的方法  他只是调用了另一个绘制
        fn: (ctx, params) => {
          if (this.reRepaintShow) {
            this.reRepaintShow = false;
            this.RenderColor('studentData');
            let data = Correction.intervaldotsDraw(this.dotsDrawCanshu);
            data.then(() => {
              this.dots_draw(this.dotsDrawCanshu, 'studentData');
            });
          }
        },
      });
    },
    // 线条渲染的颜色
    RenderColor(attribute) {
      if (attribute == 'teachData') {
        this.ctx.strokeStyle = 'red';
      } else {
        this.ctx.strokeStyle = 'black';
      }
    },
    //老师批改时间
    QueryNameCardSelectList() {
      if (!this.teachData.PenMac) {
        return;
      }
      let data = {
        penMac: this.teachData.PenMac,
        userId: this.userId,
        planId: this.planId,
      };
      queryNameCardSelectList(data).then((res) => {
        //没有获取老师批改时间
        if (res.data.result.length == 0) {
          this.teachDotsDrawCanshu = null;
          return;
        }
        res.data.result.forEach((item) => {
          let end_time = item.endTime ? item.endTime : Date.parse(new Date());
          this.getDotsv3('老师', item.beginTime, end_time);
          if (this.pictureSize == 'A3') {
            this.getDotsv3('老师', item.beginTime, end_time, '二');
          }
        });
      });
    },
    //框选区域 循环
    RenderingAMarquee() {
      this.baseTopicInformation = this.$parent.baseTopicInformation;
      this.RenderingAMarqueeFun(this.baseTopicInformation);
    },
    ////框选区域方法
    RenderingAMarqueeFun(data, stutes, canvas_click_x, canvas_click_y) {
      if (!(data && data.length > 0)) return;
      data.forEach((item) => {
        if (
          Object.prototype.toString.call(item.childrenList) ===
            '[object Array]' &&
          item.childrenList.length > 0
        ) {
          this.RenderingAMarqueeFun(
            item.childrenList,
            stutes,
            canvas_click_x,
            canvas_click_y
          );
        } else {
          this.answerareaModlle(item, stutes, canvas_click_x, canvas_click_y);
        }
      });
    },
    ////框选方法 渲染中间
    answerareaModlle(item, stutes, canvas_click_x, canvas_click_y) {
      item.answerAreaList.forEach((answerAreaitem, index) => {
        var startX = answerAreaitem.startX * this.multiple;
        var endX = answerAreaitem.endX * this.multiple;
        var startY = answerAreaitem.startY * this.multiple;
        var endY = answerAreaitem.endY * this.multiple;
        if (this.pictureSize == 'A3' && answerAreaitem.pageNo % 2 == 0) {
          startX = startX + this.canvesWight / 2;
          endX = endX + this.canvesWight / 2;
        }
        var x = (startX + endX) / 2;
        var y = (startY + endY) / 2;
        if (
          this.pictureSize == 'A3'
            ? this.cur_page + 1 == answerAreaitem.pageNo ||
              this.cur_page + 2 == answerAreaitem.pageNo
            : this.cur_page + 1 == answerAreaitem.pageNo
        ) {
          if (stutes == 'result') {
            //是-1也要  变成？号
            // if (answerAreaitem.result == -1) {} else {
            if (this.planData.planKind == '测验') {
              this.draw_score(
                (startX + endX) / 2,
                startY,
                answerAreaitem.correct_score
              );
            }
            this.RenderResults(answerAreaitem, x, y);
            // }
          } else if (stutes == 'click') {
            if (
              startX <= canvas_click_x &&
              canvas_click_x <= endX &&
              startY <= canvas_click_y && canvas_click_y <= endY
            ) {
              if (
                this.pictureSize == 'A3'
                  ? this.cur_page + 1 == answerAreaitem.pageNo ||
                    this.cur_page + 2 == answerAreaitem.pageNo
                  : this.cur_page + 1 == answerAreaitem.pageNo
              ) {
                this.singleClick(answerAreaitem, item);
              }
            }
          } else if (stutes == 'dbclick') {
            if (
              startX <= canvas_click_x &&
              canvas_click_x <= endX &&
              startY <= canvas_click_y && canvas_click_y <= endY
            ) {
              if (
                this.pictureSize == 'A3'
                  ? this.cur_page + 1 == answerAreaitem.pageNo ||
                    this.cur_page + 2 == answerAreaitem.pageNo
                  : this.cur_page + 1 == answerAreaitem.pageNo
              ) {
                this.doubleClick(answerAreaitem, item);
              }
            }
          } else {
            this.answerarea(startX, endX, startY, endY);
          }
        }
      });
    },
    // 渲染批改结果
    RenderResults(answerAreaitem, x, y) {
      let teachansDotShow = JSON.parse(localStorage.getItem('teachansDotShow'));
      let canshu = baseJs.IdFindQuestion(
        answerAreaitem.id,
        this.baseTopicInformation
      )[0];
      if (teachansDotShow && canshu && canshu.questionOutVo) {
        if (
          canshu.questionOutVo.featureCode == '600001' ||
          canshu.questionOutVo.featureCode == '700001' ||
          canshu.questionOutVo.featureCode == '800001'
        ) {
          return;
        }
      }
      if (answerAreaitem.result == 1) {
        let img = require('../../assets/img/Yes.png');
        this.draw_canves(x - 32, y - 32, img, {
          width: 36,
          height: 36,
        });
      } else if (answerAreaitem.result == 0.5) {
        let img = require('../../assets/img/banYes.png');
        this.draw_canves(x - 32, y - 32, img, {
          width: 36,
          height: 36,
        });
      } else if (answerAreaitem.result == 0) {
        let img = require('../../assets/img/No.png');
        this.draw_canves(x - 32, y - 32, img, {
          width: 36,
          height: 36,
        });
      } else if (answerAreaitem.result == -1) {
        console.log(answerAreaitem.result);
        let img = require('../../assets/img/wen.png');
        this.draw_canves(x - 32, y - 32, img, {
          width: 36,
          height: 36,
        });
      } else {
        // let img = require('../../assets/img/wen.png')
        // this.draw_canves(x - 32, y - 32, img, {
        // 	width: 20,
        // 	height: 20
        // })
      }
    },
    draw_score(x, y, data) {
      //分数变红
      this.$nextTick(() => {
        if (typeof data == 'undefined') {
          return;
        }
        $('#' + this.canvasIdName).removeLayer(x + 'zhangclear' + y);
        let score = data == '-1' ? 0 : this.hasDot(data);
        if (score || score === 0) {
          $('#' + this.canvasIdName)
            .addLayer({
              name: x + 'zhangclear' + y,
              method: 'drawText',
              fillStyle: 'red',
              strokeStyle: 'rgba(255,255,255,0.2)',
              x: x,
              y: y + 10 - this.initialValueStartY * this.multiple,
              fontSize: (24 * this.multiple) / 8,
              fontFamily: 'Verdana, sans-serif',
              text: score,
            })
            .drawLayers();
        }
      });
    },
    // 保留小数点后两位
    hasDot(num) {
      // 保留小数点后两位
      var result = num.toString().indexOf('.'); // 查找是否含有小数点
      if (result != -1) {
        return num.toString().substring(0, result + 3); //有小数点保留两位小数
      } else {
        return num;
      }
    },
    draw_canves(x, y, img, size) {
      this.$nextTick(() => {
        $('#' + this.canvasIdName).removeLayer(x + 'clear' + y);
        $('#' + this.canvasIdName)
          .drawImage({
            source: img,
            layer: true,
            x: x + 32,
            y: y + 32 - this.initialValueStartY * this.multiple,
            width: (size.width * this.multiple) / 8,
            height: (size.height * this.multiple) / 8,
            fillStyle: 'rgba(255, 0, 255, 0.25)',
            name: x + 'clear' + y,
          })
          .drawLayers();
      });
    },
    ////框选区域方法 渲染
    answerarea(x1, x2, y1, y2) {
      $('#' + this.canvasIdName).drawRect({
        layer: true,
        strokeStyle: '#deebff',
        // strokeStyle: 'red',
        x: (x2 + x1) / 2,
        y: (y1 + y2) / 2 - this.initialValueStartY * this.multiple,
        width: x2 - x1,
        height: y2 - y1,
      });
    },
    filterDuplicateRemovalTest(data) {
      let returnData;
      this.filterDuplicateRemovalShow = true;
      this.PlanUserMaxRecords = data.filter(function(item) {
        return (
          !item.manual_result &&
          item.manual_result != 0 &&
          item.auto_result != 1
        ); // 过滤出来数组中>=5的元素
      });

      // this.PlanUserMaxRecords = this.unique(this.PlanUserMaxRecords, 'sub_id')
      // console.log(this.PlanUserMaxRecords,this.baseTopicInformation,"this.baseTopicInformation")
      if (this.PlanUserMaxRecords && this.PlanUserMaxRecords.length > 0) {
        if (this.intercept && this.whetherWrong) {
          this.switchWrongTopic({
            type: 'int',
          });
        }
        returnData = true;
      } else {
        this.$message('该学生暂无未批改的题');
        this.$store.commit('getwhetherWrong', false);
        returnData = false;
      }
      return returnData;
    },

    //批改结果重新排序过滤
    filterDuplicateRemoval(data) {
      let returnData;
      this.filterDuplicateRemovalShow = true;
      this.PlanUserMaxRecords = data.filter(function(item) {
        return (
          !item.manual_result &&
          item.manual_result != 0 &&
          item.auto_result != 1
        ); // 过滤出来数组中>=5的元素
      });
      let PlanUserMaxRecords = this.rePlanUserMaxRecords(
        this.baseTopicInformation,
        this.PlanUserMaxRecords
      );
      this.PlanUserMaxRecords = this.PlanUserMaxRecords.concat(
        PlanUserMaxRecords
      );
      // this.PlanUserMaxRecords = this.unique(this.PlanUserMaxRecords, 'sub_id')
      // console.log(this.PlanUserMaxRecords,this.baseTopicInformation,"this.baseTopicInformation")
      if (this.PlanUserMaxRecords && this.PlanUserMaxRecords.length > 0) {
        if (this.intercept && this.whetherWrong) {
          this.switchWrongTopic({
            type: 'int',
          });
        }
        returnData = true;
      } else {
        this.$message('该学生暂无未批改的题');
        this.$store.commit('getwhetherWrong', false);
        returnData = false;
      }
      return returnData;
    },
    //this.PlanUserMaxRecords
    rePlanUserMaxRecords(baseTopicInformation, PlanUserMaxRecords) {
      let shuzu = [];
      let data = {
        audit_status: 1,
        auto_result: null,
        manual_result: null,
        params: null,
        plan_id: this.planId,
        result_id: null,
        score: 0,
        serial: 1,
        sub_id: 67864,
        sub_parent_id: 0,
        user_id: this.userId,
      };
      baseTopicInformation.forEach((items) => {
        if (items.childrenList && items.childrenList.length > 0) {
          items.childrenList.forEach((item) => {
            item.answerAreaList.forEach((answerAreaitem) => {
              if (!answerAreaitem.result && answerAreaitem.result != 0) {
                let canshu = JSON.parse(JSON.stringify(data));
                canshu.serial = answerAreaitem.serialAnswer;
                canshu.sub_id = item.id;
                canshu.sub_parent_id = items.id;
                shuzu.push(canshu);
              }
            });
          });
        } else {
          items.answerAreaList.forEach((answerAreaitem) => {
            if (!answerAreaitem.result && answerAreaitem.result != 0) {
              let canshu = JSON.parse(JSON.stringify(data));
              canshu.serial = answerAreaitem.serialAnswer;
              canshu.sub_id = items.id;
              canshu.sub_parent_id = 0;
              shuzu.push(canshu);
            }
          });
        }
      });
      return shuzu;
    },
    //数组去重
    unique(tempArr, attribute) {
      for (let i = 0; i < tempArr.length; i++) {
        for (let j = i + 1; j < tempArr.length; j++) {
          if (tempArr[i][attribute] == tempArr[j][attribute]) {
            tempArr.splice(j, 1);
            j--;
          }
        }
      }
      return tempArr;
    },
    //批改结果赋值
    QueryPlanUserMaxRecords() {
      let that = this;
      let data = {
        planId: this.planId,
        classId: this.classId,
        userId: this.userId,
      };
      queryPlanUserMaxRecords(data).then((res) => {
        this.queryPlanUserMaxList = res.data.result;
        if (res && res.data && res.data.result && res.data.result.length > 0) {
          this.baseTopicInformation = baseJs.CorrectionResults(
            this.baseTopicInformation,
            res.data.result
          );
          let cnashu = JSON.parse(JSON.stringify(this.baseTopicInformation));
          let recombination = baseJs.recombinationParent(cnashu);
          this.RenderingAMarqueeFun(recombination, 'result');
        } else {
          this.RenderingAMarqueeFun(this.baseTopicInformation, 'result');
        }
        if (this.whetherWrong) {
          if (!this.filterDuplicateRemovalShow) {
            //若果切换下一题有问题  只改了这一个位置  注销下面换成上面就是原版
            // this.filterDuplicateRemoval(this.queryPlanUserMaxList)
            that.filterDuplicateRemovalTest(this.queryPlanUserMaxList);
          }
        }
      });
    },
    //单击事件
    canvasclick() {
      let account = this.teachLoginData ? this.teachLoginData.account : null;
      if (account == '18651571984') {
        return;
      }
      if (this.statues == '家长') {
        return;
      }
      if (this.clickFun) {
        return;
      }
      var canvas_click_x;
      var canvas_click_y;
      var pigai;
      var that = this;

      function handleAction(event) {
        clearTimeout(that.time);
        ++that.clickI;
        that.time = setTimeout(() => {
          let canvas = document.getElementById(that.canvasIdName);
          var rect = canvas.getBoundingClientRect();
          canvas_click_x =
            event.clientX - rect.left * (canvas.width / rect.width);
          canvas_click_y =
            event.clientY - rect.top * (canvas.height / rect.height);
          that.RenderingAMarqueeFun(
            that.baseTopicInformation,
            'click',
            canvas_click_x,
            canvas_click_y
          );
          that.clickI = 0;
        }, 300);
        if (that.clickI > 1) {
          that.canvasclicks();
        }
      }
      document
        .getElementById(this.canvasIdName)
        .addEventListener('click', handleAction(event), {
          once: true,
        });
    },
    //双击事件
    canvasclicks() {
      if (this.statues == '家长') {
        return;
      }
      if (this.clickFun) {
        return;
      }
      var canvas_click_x;
      var canvas_click_y;
      var pigai;
      var that = this;
      clearTimeout(this.time);
      that.clickI = 0;

      function handleAction(event) {
        let canvas = document.getElementById(that.canvasIdName);
        var rect = canvas.getBoundingClientRect();
        canvas_click_x =
          event.clientX - rect.left * (canvas.width / rect.width);
        canvas_click_y =
          event.clientY - rect.top * (canvas.height / rect.height);
        if (that.intercept) {
          canvas_click_y =
            canvas_click_y + that.initialValueStartY * that.multiple;
        }
        that.RenderingAMarqueeFun(
          that.baseTopicInformation,
          'dbclick',
          canvas_click_x,
          canvas_click_y
        );
      }
      document
        .getElementById(this.canvasIdName)
        .addEventListener('dblclick', handleAction(event), {
          once: true,
        });
    },
    // 点击事件批改
    singleClick(answerAreaitem, item) {
      this.recorretedData = {
        answerAreaitem: answerAreaitem,
        item: item,
        parentId: baseJs.orgChildIdFindParents(
          item.id,
          this.baseTopicInformation
        ),
      };
      this.$store.commit('getRecorretedDatas', this.recorretedData);
      var result;
      var score;
      if (
        item.questionOutVo.featureCode == '100001' ||
        item.questionOutVo.featureCode == '300001' ||
        item.questionOutVo.featureCode == '1000001' ||
        item.questionOutVo.featureCode == '400001' ||
        item.questionOutVo.featureCode == '1200001'
      ) {
        if (answerAreaitem.result == 1) {
          result = 0;
          score = 0;
        } else if (answerAreaitem.result == 0 || answerAreaitem.result == -1) {
          result = 1;
          score = answerAreaitem.score;
          this.$parent.distinguish();
        }
      } else {
        if (answerAreaitem.result == 1) {
          result = 0;
          score = 0;
        } else if (answerAreaitem.result == 0 || answerAreaitem.result == -1) {
          result = 0.5;
          this.recorretedData.result = result;
          if (this.planData.planKind == '测验') {
            this.$parent.scoreCorrection();
            return;
          }
        } else if (answerAreaitem.result == 0.5) {
          result = 1;
          score = answerAreaitem.score;
          this.$parent.distinguish();
        }
      }
      if (this.planData.planKind != '测验') {
        score = 0;
      }
      this.recorreted(answerAreaitem, item, result, score);
    },
    //老师批改
    recorreted(answerAreaitem, item, result, score) {
      let data = {
        plan_id: this.planId,
        student_id: this.userId,
        mac: this.studentData.PenMac,
        begin_time: this.planData.beginTime,
        end_time: this.planData.endTime,
        pageId: this.pageId,
        questionId: item.id,
        parentId:
          baseJs.orgChildIdFindParent([item.id], this.baseTopicInformation)
            .length > 0
            ? baseJs.orgChildIdFindParent(
                [item.id],
                this.baseTopicInformation
              )[0]
            : 0,
        serialAnswer: answerAreaitem.serialAnswer,
        manual_result: answerAreaitem.score == score ? 1 : result ? result : 0,
        similarity: answerAreaitem.score == score ? 1 : result ? result : 0,
        score: score ? score : answerAreaitem.score ? 0 : -1,
        startX: answerAreaitem.startX,
        endX: answerAreaitem.endX,
        startY: answerAreaitem.startY,
        endY: answerAreaitem.endY,
      };
      Recorreted(data).then((res) => {
        // if (res.data.msg == "无学生书写数据，无法手动批改") {
        // 	this.$message("无学生书写数据，无法手动批改")
        // 	return
        // } else {
        this.$message('重新批改成功');
        // }
        this.QueryPlanUserMaxRecords();
      });
    },
    // 双击事件显示
    doubleClick(answerAreaitem, item) {
      this.recorretedData = {
        answerAreaitem: answerAreaitem,
        item: item,
        parentId: baseJs.orgChildIdFindParents(
          item.id,
          this.baseTopicInformation
        ),
      };
      this.$store.commit('getRecorretedDatas', this.recorretedData);
      this.$parent.doubleClick();
    },
    // 清除websoket
    getWebSocketclose() {
      clearInterval(this.timer2);
      clearInterval(this.timer3);
      clearInterval(this.timer4);
      if (this.ws) {
        this.ws.close();
      }
      if (this.ws1) {
        this.ws1.close();
      }
      if (this.ws4) {
        this.ws4.close();
      }
    },
    // 重组数据
    ReorganizeAata(data) {
      let canshu = baseJs.CorrectionResultsn(this.baseTopicInformation, [data]);
      this.baseTopicInformation = canshu.baseTopicInformation;
      canshu.CorrectionResultsItem = baseJs.recombination(
        canshu.CorrectionResultsItem
      );
      canshu.CorrectionResultsItem.forEach((item) => {
        let startX = item.startX * this.multiple;
        let endX = item.endX * this.multiple;
        let startY = item.startY * this.multiple;
        let endY = item.endY * this.multiple;
        if (this.pictureSize == 'A3' && item.pageNo % 2 == 0) {
          startX = startX + this.canvesWight / 2;
          endX = endX + this.canvesWight / 2;
        }
        let x = (startX + endX) / 2;
        let y = (startY + endY) / 2;
        if (item.pageId == this.pageId) {
          if (this.planData.planKind == '测验') {
            this.draw_score((startX + endX) / 2, startY, item.correct_score);
          }
          this.RenderResults(item, x, y);
        } else {
          if (this.pictureSize == 'A3') {
            if (item.pageId == this.pageIds[this.cur_page + 1]) {
              if (this.planData.planKind == '测验') {
                this.draw_score(
                  (startX + endX) / 2,
                  startY,
                  item.correct_score
                );
              }
              this.RenderResults(item, x, y);
            }
          }
        }
      });
    },
    // 创建websoket
    getWebSocket() {
      clearInterval(this.timer2);
      clearInterval(this.timer3);
      if (this.ws) {
        this.ws.close();
      }
      if (this.ws1) {
        this.ws1.close();
      }
      let params1 = 'KS0012' + this.studentData.PenMac;
      let studentId = this.userId;
      let planId = this.planId;
      let params2 = 'KS0014' + planId + ':' + studentId;
      const url = 'wss://websocket.youcun.tech/wss/webSocket-link/kafkaSocket';
      WebSocketFunc(
        this.ws,
        params1,
        url,
        (data) => {
          data = JSON.parse(data);
          if (data.flag != '9') {
            data.result = JSON.parse(data.result);
            let canshu = {
              data: data.result,
              cxt: this.ctx,
              n: this.multiple,
              pictureSize: this.pictureSize,
              width: this.canvesWight,
              stutes: '一',
              height: this.canvesHeight,
            };
            this.real_dots_draw(canshu, 'studentData');
          }
        },
        (ws) => {
          this.ws = ws;
        },
        (timer) => {
          this.timer2 = timer;
        }
      );
      WebSocketFunc(
        this.ws1,
        params2,
        url,
        (data) => {
          data = JSON.parse(data);
          if (data.flag != '9') {
            this.ReorganizeAata(data.result);
          }
        },
        (ws) => {
          this.ws1 = ws;
        },
        (timer) => {
          this.timer3 = timer;
        }
      );
    },
    //实时点数据渲染判断
    QueryCorrectUserIdByMac(data) {
      queryCorrectUserIdByMac({
        penMac: this.teachData.PenMac,
      }).then((res) => {
        if (this.userId == res.data.result) {
          data = JSON.parse(data);
          data.result = JSON.parse(data.result);
          let canshu = {
            data: data.result,
            cxt: this.ctx,
            n: this.multiple,
            pictureSize: this.pictureSize,
            width: this.canvesWight,
            stutes: '一',
            height: this.canvesHeight,
          };
          this.real_dots_draw(canshu, 'teachData');
        }
      });
    },
    // 创建老师websoket
    teachGetWebSocket() {
      clearInterval(this.timer4);
      if (this.ws4) {
        this.ws4.close();
      }
      let params4 = 'KS0012' + this.teachData.PenMac;
      const url = 'wss://websocket.youcun.tech/wss/webSocket-link/kafkaSocket';
      WebSocketFunc(
        this.ws4,
        params4,
        url,
        (data) => {
          let canshu = JSON.parse(data);
          if (canshu.flag != '9') {
            this.QueryCorrectUserIdByMac(data);
          }
        },
        (ws) => {
          this.ws4 = ws;
        },
        (timer) => {
          this.timer4 = timer;
        }
      );
    },
  },
};
</script>

<style>
.overflowHidden {
  height: 85vh;
  overflow: auto;
}
</style>
