<template>
	<div id="zhangcontainer" class="zhangcontainer" style="overflow: auto;width: 100%;">
		<div id="questionanswerList" style="display:flex;">
			<span style="white-space: nowrap;">
				{{$store.state.teachlanguagedata.answers}}：
			</span>
			<div>
				<span v-for="(item,index) in recorretedData.item.questionOutVo.answerList">
					<div style="display: flex;">
						<span style="white-space: nowrap;">第{{index+1}}空: </span>
						<span v-html="item.value"></span>
					</div>
					<div style="display: flex;" v-if="item.answerExtendList&&item.answerExtendList.length>0">
						<span v-for="(items,indexs) in item.answerExtendList">
							<span style="white-space: nowrap;">第{{index+1}}空第{{indexs+1}}个扩展答案: </span>
							<span v-html="items.value"></span>
						</span>
					</div>
				</span>
			</div>
		</div>
		<div id="questionanalysis" style="display:flex;">
			<span style="white-space: nowrap;">
				{{$store.state.teachlanguagedata.analysis}}：
			</span>
			<span @mousewheel.prevent="rollImg" ref="imgDiv" v-html="recorretedData.item.questionOutVo.analysis"></span>
		</div>
	</div>
</template>

<script>
	import needChange from './needChange.vue'
	export default {
		name: 'Class',
		data() {
			return {
				recorretedData: {
					item: {
						questionOutVo: {}
					}
				}, //点击区域信息
			}
		},
		components: {
			needChange
		},
		mounted() {
		},
		methods: {
			rollImg() {
				var zoom = parseInt(this.$refs.imgDiv.style.zoom) || 100;
				zoom += event.wheelDelta / 12;
				if (zoom > 0)
					this.$refs.imgDiv.style.zoom = zoom + "%";
				return false;
			},
			//初始化
			init() {
				let data = {...this.$store.state.$recorretedDatas}
				data.item.questionOutVo.analysis=this.textcircled(data.item.questionOutVo.analysis)
				data.item.questionOutVo.answerList.forEach(item=>{
					item.value=this.textcircled(item.value)
				})
				// data.item.questionOutVo.analysis=data.item.questionOutVo.analysis.replace(/-/g, '=')
				//转大于小于号
				data.item.questionOutVo.analysis=data.item.questionOutVo.analysis.replace(/&amp;lt;/g, '<')
				data.item.questionOutVo.analysis=data.item.questionOutVo.analysis.replace(/&amp;gt;/g, '>')
				console.log(data.item.questionOutVo.analysis);
				this.recorretedData = data

				this.Mathqunati()
			},
			textcircled(val) {
				if (val) {
					let a = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫']
					let data = val.replace(/\\textcircled {(1)}/g, a[0])
					data = data.replace(/\\textcircled {(2)}/g, a[1])
					data = data.replace(/\\textcircled {(3)}/g, a[2])
					data = data.replace(/\\textcircled {(4)}/g, a[3])
					data = data.replace(/\\textcircled {(5)}/g, a[4])
					data = data.replace(/\\textcircled {(6)}/g, a[5])
					data = data.replace(/\\textcircled {(7)}/g, a[6])
					data = data.replace(/\\textcircled {(8)}/g, a[7])
					data = data.replace(/\\textcircled {(9)}/g, a[8])
					data = data.replace(/\\textcircled {(10)}/g, a[9])
					data = data.replace(/\\textcelsius/g, "度")
					data = data.replace(/&amp;nbsp;/g, ' ')
					data = data.replace(/\$/g, '')
					data = data.replace(/@@@@/g, '')
					return data
				}
			},
			Mathqunati() {
				this.$nextTick(function() { //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
					if (this.commonsVariable.isMathjaxConfig) { //判断是否初始配置，若无则配置。
						this.commonsVariable.initMathjaxConfig();
					}
					this.commonsVariable.MathQueue("zhangcontainer");
					this.Mathqunatishow = true
				})
			},
		}
	}
</script>

<style>
</style>
