<template>
	<div v-if="loginData.account != '18651571984' && recorretedData.answerAreaitem && recorretedData.answerAreaitem.serialAnswer">
		<div v-if="planKind == '测验'" style="display: flex;align-items: center;">
			<div style="display: flex;">
				<div>批改：</div>
				<div class="textAlignLeft">第{{ recorretedData.answerAreaitem.serialAnswer }}空</div>
				<div>
					<img style="margin: 0 30px;" v-show="autoResult !== 1"
						@click="dialogscore = recorretedData.answerAreaitem.score; dialogscoreResult = 1; getdialogscore(true);"
						src="../../assets/img/Yes.png" />
					<img style="margin: 0 30px;" v-show="autoResult !== 0"
						@click="dialogscore = 0; dialogscoreResult = 0; getdialogscore();" src="../../assets/img/No.png" />
					<img style="margin: 0 30px;" v-show="autoResult != 0.5"
						@click="dialogscore = 0.5 * recorretedData.answerAreaitem.score; dialogevaluateshow = true; dialogscoreResult = 0.5;"
						src="../../assets/img/banYes.png" />
					<!-- <el-button @click="dialogscore=recorretedData.answerAreaitem.score;getdialogscore();">√</el-button>
					<el-button @click="dialogscore=0;getdialogscore();">×</el-button>
					<el-button @click="dialogscore=0.5*recorretedData.answerAreaitem.score;evaluateshow=true;">乄</el-button> -->
				</div>
				<!-- <el-input style="width: 180px;margin-bottom: 10px;" v-model="dialogscore"></el-input>
				<div style="margin: 10px 0;">
					<el-button @click="getdialogscore();">
						{{$store.state.teachlanguagedata?$store.state.teachlanguagedata.confirm:'确定'}}</el-button>
					<el-button>
						{{$store.state.teachlanguagedata?$store.state.teachlanguagedata.cancel:'取消'}}</el-button>
				</div> -->
			</div>
		</div>
		<div v-else style="display: flex;align-items: center;">
			<div style="display: flex;">
				<div>批改：</div>
				<div class="textAlignLeft">第{{ recorretedData.answerAreaitem.serialAnswer }}空</div>
				<!-- <el-select v-model="dialogscoreResult" placeholder="请输入批改结果">
					<el-option v-for="item in [{name:'√',id:1},{name:'×',id:0},{name:'乄',id:0.5}]" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select></br> -->
				<!-- 课前批改 -->
				<div>
					<img style="margin: 0 30px;" @click="dialogscoreResult = 1; dialogscore = -1; getdialogscores(true);"
						v-show="autoResults !== 1" src="../../assets/img/Yes.png" />
					<img style="margin: 0 30px;" @click="dialogscoreResult = 0; dialogscore = -1; getdialogscores();"
						v-show="autoResults !== 0" src="../../assets/img/No.png" />
					<img style="margin: 0 30px;" @click="dialogscoreResult = 0.5; dialogscore = -1; getdialogscores(true);"
						v-show="autoResults !== 0.5" src="../../assets/img/banYes.png" />
					<!-- <el-button @click="dialogscoreResult=1;getdialogscores();">√</el-button>
					<el-button @click="dialogscoreResult=0;getdialogscores();">×</el-button>
					<el-button @click="dialogscoreResult=0.5;ggetdialogscores();">乄</el-button> -->
				</div>
			</div>
		</div>
		<el-dialog :title="$store.state.teachlanguagedata.Scorecannotbeblank + ':' + recorretedData.answerAreaitem.score"
			:visible.sync="dialogevaluateshow" append-to-body style="text-align: center;">
			<div v-if="dialogevaluateshow">
				<el-input style="width: 180px;margin-bottom: 10px;" v-model="dialogscore"></el-input>
				<div style="margin-top: 20px;">
					<el-button @click="dialogevaluateshow = false">取消</el-button>
					<el-button @click="getdialogscore(); dialogevaluateshow = false;">确定</el-button>
				</div>
			</div>
		</el-dialog>
		<distinguish ref="distinguish" @wrongAnswerCorretedSuccess="wrongAnswerCorretedSuccess"></distinguish>
	</div>
</template>

<script>
import distinguish from './distinguish.vue'
export default {
	name: 'Class',
	components: {
		distinguish
	},
	data() {
		return {
			autoResult: 0,
			autoResults: 0,
			loginData: {
				account: null
			},
			dialogscore: null,
			dialogscoreResult: null,
			dialogscoreshow: false,
			dialogevaluateshow: false,
			planKind: null,
			recorretedData: {
				answerAreaitem: {
					score: null
				}
			},
		}
	},
	watch: {
		dialogscore(val) {
			if (this.planKind == '测验') {
				if (val > this.recorretedData.answerAreaitem.score) {
					this.dialogscore = this.recorretedData.answerAreaitem.score
				} else if (val < 0) {
					this.dialogscore = 0.5 * this.recorretedData.answerAreaitem.score
				}
			}
		}
	},
	props: [
		"index", "userId", "planId", "pageId", "planData"
	],
	mounted() { },
	methods: {
		previousQuestion() {
			this.$emit('previousQuestion');
		},
		nextQuestion() {
			this.$emit('nextQuestion');
		},
		init() {
			let data = {
				...this.$store.state.$recorretedDatas
			}
			// this.autoResult = data.answerAreaitem.manual_result === null ? data.answerAreaitem.auto_result : data.answerAreaitem.manual_result
			this.autoResult = data.answerAreaitem.result
			this.autoResults = data.answerAreaitem.result
			//小程序进入拿teachlogin
			this.loginData = JSON.parse(localStorage.getItem('teachlogin'))==null?JSON.parse(localStorage.getItem('wxTeachLogin')):JSON.parse(localStorage.getItem('teachlogin'))
			//
			this.recorretedData.item = this.$store.state.$recorretedDatas.item
			this.recorretedData.parentId = this.$store.state.$recorretedDatas.parentId
			this.recorretedData.answerAreaitem = this.index || this.index == 0 ? this.$store.state.$recorretedDatas.item.answerAreaList[this.index] : this.$store.state.$recorretedDatas.answerAreaitem
			//小程序进入拿planKind
			this.planKind = JSON.parse(localStorage.getItem('teachQuestionSetInformation'))==null?localStorage.getItem('wxPlanKind'):JSON.parse(localStorage.getItem('teachQuestionSetInformation')).planKind
			//	
			if (!this.recorretedData.answerAreaitem) {
				return
			}
			if (this.planKind == '测验') {
				this.dialogscore = this.recorretedData.answerAreaitem.correct_score
			} else {
				this.dialogscoreResult = this.recorretedData.answerAreaitem.result
			}
			this.$forceUpdate()
		},
		//老师修改批改结果
		getdialogscore(show) {
			if (show) {
				this.$refs.distinguish.init()
			}
			this.$emit('recorreted', this.recorretedData.answerAreaitem, this.recorretedData.item, this.dialogscoreResult, this.dialogscore);
			this.autoResult = this.dialogscoreResult
			this.$forceUpdate()
			if (this.dialogscoreResult != 1 && this.$store.state.whetherWrong) {
				this.nextQuestion()
			}
			// this.nextQuestion()
			// this.$parent.recorreted(this.recorretedData.answerAreaitem,this.recorretedData.item,this.recorretedData.result,this.dialogscore)
		},
		getdialogscores(show) {
			this.autoResults = this.dialogscoreResult
			if (show) {
				this.$refs.distinguish.init()

			}
			if(this.dialogscoreResult==0){
				this.$emit('recorreted', this.recorretedData.answerAreaitem, this.recorretedData.item, this.dialogscoreResult, this.dialogscore);
			}
			
			if (this.dialogscoreResult != 1 && this.$store.state.whetherWrong) {
				this.nextQuestion()
			}
			// this.$parent.recorreted(this.recorretedData.answerAreaitem,this.recorretedData.item,this.recorretedData.result,this.dialogscore)
		},
		wrongAnswerCorretedSuccess(e){
			if(e==200){
				this.autoResults = this.dialogscoreResult
				this.$emit('recorreted', this.recorretedData.answerAreaitem, this.recorretedData.item, this.dialogscoreResult, this.dialogscore);
			}else{
				this.$message("重新修改失败")
			}
		}

	}
}
</script>

<style></style>
