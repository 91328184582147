<template>
	<el-dialog title="请输入评价" :visible.sync="evaluateshow"  append-to-body style="text-align: center;">
		<div>
			<span style="margin-right: 10px;">评价</span>
			<el-select clearable filterable v-model="evaluateji">
				<el-option v-for="option in queryListByDictValuelist" :key="option.id" :label="option.valueName"
					:value="option.valueName">
					{{ option.valueName }}
				</el-option>
			</el-select>
		</div>
		<div>
			<span style="margin-right: 10px;">评语</span>
			<el-input style="width: auto;margin-top: 20px;" v-model="evaluateyu" placeholder="请输入评语"></el-input>
		</div>
		<div style="margin-top: 20px;">
			<el-button @click="evaluateshow=false">取消</el-button>
			<el-button @click="evaluate()">确定</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		queryListByDictValue
	} from '../../api/base.js'
	import {
		submitEvaluation,
		gotEvaluation,
	} from '../../api/Correct.js'
	export default {
		data() {
			return {
				evaluateshow:false,
				queryListByDictValuelist:[],
				evaluateji:null,
				evaluateyu:null,
			}
		},
		methods: {
			init(){
				this.planId=this.$parent.planId
				this.userId=this.$parent.userId
				this.evaluateshow=true
				this.querylistbydictvalue()
				this.gotevaluation()
			},
			querylistbydictvalue() {
				queryListByDictValue('dictValue=' + 'evaluation').then(res => {
					this.queryListByDictValuelist = res.data.result
				})
			},
			gotevaluation() {
				let data = {
					userId: this.userId,
					planId: this.planId,
				}
				gotEvaluation(data).then(res => {
					this.evaluateji = res.data.result.evaluation
					this.evaluateyu = res.data.result.remark
				})
			},
			//评价
			evaluate() {
				let data = {
					"evaluation": this.evaluateji,
					"planId": this.planId,
					"remark": this.evaluateyu,
					"userId": this.userId
				}
				submitEvaluation(data).then(res => {
					this.evaluateshow = false
				})
			},
		},
	}
</script>

<style>
</style>