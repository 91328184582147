var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"displayFlex justifyContentCenter",attrs:{"id":"tuozhuang"}},[(!_vm.$store.state.rollShow)?_c('div',{staticClass:"overflowHidden",style:({ width: _vm.canvesWight })},[(_vm.intercept && _vm.initialValueStartY)?_c('canvas',{ref:"myCanves",style:({
        transform:
          _vm.whetherWrong &&
          _vm.pictureSize == 'A3' &&
          _vm.pageList &&
          _vm.pageList[_vm.pageListIndex].pageNo % 2 != 1
            ? 'translateX(-50%)'
            : 'translateX(0px)',
      }),attrs:{"id":_vm.canvasIdName,"width":_vm.canvesWight,"height":(_vm.initialValueEndY - _vm.initialValueStartY) * _vm.multiple},on:{"mousewheel":function($event){$event.preventDefault();return _vm.rollImg1.apply(null, arguments)},"dblclick":function($event){return _vm.canvasclicks()},"click":function($event){return _vm.canvasclicks()},"touchstart":_vm.touchStart,"touchmove":_vm.touchmove}}):_c('canvas',{ref:"myCanves",attrs:{"id":_vm.canvasIdName,"width":_vm.canvesWight,"height":_vm.canvesHeight},on:{"dblclick":function($event){return _vm.canvasclicks()},"click":function($event){return _vm.canvasclicks()},"mousewheel":function($event){$event.preventDefault();return _vm.rollImg1.apply(null, arguments)},"touchstart":_vm.touchStart,"touchmove":_vm.touchmove}})]):_c('div',{staticClass:"overflowHidden",style:({ width: _vm.canvesWight })},[(_vm.intercept && _vm.initialValueStartY)?_c('canvas',{ref:"myCanves",style:({
        transform:
          _vm.whetherWrong &&
          _vm.pictureSize == 'A3' &&
          _vm.pageList &&
          _vm.pageList[_vm.pageListIndex].pageNo % 2 != 1
            ? 'translateX(-50%)'
            : 'translateX(0px)',
      }),attrs:{"id":_vm.canvasIdName,"height":(_vm.initialValueEndY - _vm.initialValueStartY) * _vm.multiple,"width":_vm.canvesWight},on:{"dblclick":function($event){return _vm.canvasclicks()},"click":function($event){return _vm.canvasclicks()},"touchstart":_vm.touchStart,"touchmove":_vm.touchmove}}):_c('canvas',{ref:"myCanves",attrs:{"id":_vm.canvasIdName,"width":_vm.canvesWight,"height":_vm.canvesHeight},on:{"dblclick":function($event){return _vm.canvasclicks()},"click":function($event){return _vm.canvasclicks()},"touchstart":_vm.touchStart,"touchmove":_vm.touchmove}})])])}
var staticRenderFns = []

export { render, staticRenderFns }