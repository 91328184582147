<template>
	<el-dialog :visible.sync="dialogFormVisiblebi"  v-eldragdialog append-to-body style="text-align: center;width: 100%;">
		  <el-tabs v-model="activeName">
		    <el-tab-pane label="修改结果" name="first">
				<div v-if="scoreCorrectionShow">
					<!-- 批改对错 -->
					<scoreCorrections @previousQuestion="previousQuestion" @nextQuestion="nextQuestion" :userId='userId' :planId='planId' :pageId="pageId" :planData="planData" ref="scoreCorrections"  @recorreted="recorreted" @distinguish="distinguish"></scoreCorrections>
				</div>
				<!-- switchQuestions上一题下一题 -->
				<switchQuestions @getdialogFormVisiblebi="getdialogFormVisiblebi" v-if="$store.state.whetherWrong" :userId='userId' :planId='planId' ref="switchQuestions" @previousQuestion="previousQuestion" @nextQuestion="nextQuestion"></switchQuestions>
				<!-- 答案解析 -->
				<AnswerAnalysis ref="AnswerAnalysis"></AnswerAnalysis>
				<needChange ref="needChange"></needChange>
			</el-tab-pane>
			<!-- 放下面一行里 v-if="teachlogin&&teachlogin.account=='18651571984'||teachlogin&&teachlogin.account=='13381530927'" -->
		    <el-tab-pane label="批改参数设置" name="second" v-if="loginVerification" >
				<!-- 第一个就是批改参数设置 -->
				<Revision ref="Revision"  v-if="dialogFormVisiblebi&&RevisionShow&&recorretedData.answerAreaitem.serialAnswer==(index+1)" :student_id="userId" v-for="(item,index) in recorretedData.item.questionOutVo.answerList"
					:RevisionData="item" :RevisionSerial="index+1" :questionListData="recorretedData.item" :questionListId="recorretedData.item.id">
					</Revision>
				<div v-for="(item,index) in recorretedData.item.questionOutVo.answerList">
					<div v-if="dialogFormVisiblebi&&RevisionShow&&item.answerExtendList&&item.answerExtendList.length>0">
						<Revision ref="Revision" :student_id="userId" v-for="(items,index) in item.answerExtendList"
							:RevisionData="items" :RevisionDataParent="item" :RevisionStatues="index+1" :RevisionSerial="recorretedData.answerAreaitem.serialAnswer" :questionListData="recorretedData.item"
							:questionListId="recorretedData.item.id" :parentId="recorretedData.parentId" :key="recorretedData.item.id"></Revision>
					</div>
				</div>
			</el-tab-pane>
		  </el-tabs>
	</el-dialog>
</template>

<script>
	import AnswerAnalysis from './AnswerAnalysis.vue'
	import scoreCorrections from './scoreCorrections.vue'
	import needChange from './needChange.vue'
	import switchQuestions from './switchQuestions.vue'
	import Revision from "../../components/Revision.vue"
	export default {
		props: [
			'canvasIdName',
		],
		name: 'Class',
		data() {
			return {
				loginVerification:true,
				activeName:"first",
				dialogFormVisiblebi: false,
				RevisionShow:false,//重新批改
				recorretedData:{
					item:{
						questionOutVo:{
							answerList:null
						}
					}
				},//点击区域信息
				userId:null,
				planId:null,
				teachlogin:{
					account:null
				},
				teachloginShow:false,    //是false  要加权限再改回来
 				scoreCorrectionShow:false,
				inNumber:1,//初始化进入的数字
				canvasIdNamejp:''    //判断进入是否是这个canvas
			}
		},
		watch:{
			dialogFormVisiblebi(val){
				if(!val){
					this.$store.commit('getSubmitCheckStatuShow', false)
				}
			},
			'$store.state.$recorretedDatas'(val){
				if(this.canvasIdName==this.canvasIdNamejp){
					this.init()
				}
				
			}
		},
		mounted() {
		},
		components: {
			AnswerAnalysis,
			needChange,
			Revision,
			scoreCorrections,
			switchQuestions
		},
		methods: {
			getdialogFormVisiblebi(){
				this.dialogFormVisiblebi=false
				this.$store.commit('getwhetherWrong', false)
			},
			previousQuestion(){
				//上一题下一题
				this.$parent.previousQuestion()
			},
			nextQuestion(){
				this.$parent.nextQuestion()
			},
			init(canvasIdName) {
				this.canvasIdNamejp=canvasIdName
						// account: "13381530927" type: "teacher"
			this.teachlogin=JSON.parse(localStorage.getItem('teachlogin'))
			// console.log(this.teachlogin,1);
				// 小程序端找不到这个
			if(this.teachlogin==null){
				this.teachlogin = this.$parent.teachlogin
				// console.log(this.teachlogin,2);
			}
			
			 if(this.teachlogin.type.indexOf('class_master')!=-1){
				this.teachloginShow=true
			}
			//登陆验证判断正式环境测试环境
			// ||window.location.href.indexOf('192.168')!=-1
			if(window.location.href.indexOf('localhost')!=-1||window.location.href.indexOf('testteach')!=-1){
				this.loginVerification=true
			}else{
				//正式环境
				this.loginVerification=(this.teachlogin&&this.teachlogin.account=='18651571984'||this.teachlogin&&this.teachlogin.account=='13381530927')?true:false
			}
		
				this.dialogFormVisiblebi = true
				this.userId=this.$parent.userId
				this.planId=this.$parent.planId
				this.pageId=this.$parent.pageId
				this.planData=this.$parent.planData
				this.recorretedData=this.$store.state.$recorretedDatas
				// console.log(this.recorretedData,'this.recorretedData')				
				this.scoreCorrectionShow=true
				// console.log(this.scoreCorrectionShow,'scoreCorrectionShow');
				this.$nextTick(()=>{
					this.$refs.AnswerAnalysis.init()
					this.$refs.scoreCorrections.init()
					this.$refs.needChange.init(this.userId,this.planId)
					this.RevisionShow=true
				})
			},
			distinguish(){
				this.$parent.distinguish()
			},
			// 老师批改
			recorreted(answerAreaitem,item,result,dialogscore){
				this.result=result
				this.dialogscore=dialogscore
				this.$parent.recorreted(answerAreaitem,item,result,dialogscore)
			},
		}
	}
</script>

<style>
</style>
