<template>
	<el-dialog append-to-body style="text-align: center;" :title="$store.state.teachlanguagedata?$store.state.teachlanguagedata.Scorecannotbeblank:'请输入当前空分数'" :visible.sync="dialogscoreshow">
		<el-input style="width: 180px;margin-bottom: 10px;" v-model="dialogscore"></el-input><br/>
		<el-button @click="getdialogscore();dialogscoreshow=false"> {{$store.state.teachlanguagedata?$store.state.teachlanguagedata.confirm:'确定'}}</el-button>
		<el-button @click="dialogscoreshow=false"> {{$store.state.teachlanguagedata?$store.state.teachlanguagedata.cancel:'取消'}}</el-button>
	</el-dialog>
</template>

<script>
	export default {
		name: 'Class',
		data() {
			return {
				dialogscore:null,
				dialogscoreshow:false,
				recorretedData:{},
			}
		},
		watch: {
			dialogscore(val) {
				if (val > this.recorretedData.answerAreaitem.score) {
					this.dialogscore = this.recorretedData.answerAreaitem.score
				} else if (val < 0) {
					this.dialogscore = 0.5 * this.recorretedData.answerAreaitem.score
				}
			}
		},
		mounted() {
		},
		methods: {
			init(){
				this.recorretedData=this.$store.state.$recorretedDatas
				this.dialogscoreshow=true
			},
			//老师修改批改结果
			getdialogscore(){
				this.$parent.recorreted(this.recorretedData.answerAreaitem,this.recorretedData.item,this.recorretedData.result,this.dialogscore)
			},
		}
	}
</script>

<style>
</style>