import request from '@/utils/request'
import requesthttps from '@/utils/requesthttps'
export function getCuotiliebiao(data){
    return request({
        url:'/getcuotiliebiao',
        method:'post',
        data
    })
}
export function getZhishidianliebiao(data){
    return request({
        url:'/getzhishidianliebiao',
        method:'post',
        data
    })
}
export function getThinkliebiao(data){
    return request({
        url:'/getthinkliebiao',
        method:'post',
        data
    })
}
export function getPlanIds(data){
    return request({
        url:'/get_plan_ids',
        method:'post',
        data
    })
}
export function queryClassRankList(data){
    return requesthttps({
        url:'/reportCenter-web/api/report/class/queryClassRankList',
        method:'post',
        data
    })
}
export function queryClassScoreDistribution(data){
    return requesthttps({
        url:'/reportCenter-web/api/report/class/queryClassScoreDistribution',
        method:'post',
        data
    })
}



