<template>
	<el-dialog title="识别结果" :visible.sync="IdentificationResultsShow" append-to-body style="text-align: center;">
		<div class="tagText">
			<el-tag type="warning" effect="dark">*请确认识别结果是否和学生书写一致 如果不一致请手动修改为正确的识别结果</el-tag>
		</div>
		<div id="zhangcontainer" class="zhangcontainer">
			<span>修改的正确结果:</span>
			<el-input v-model="inputKeyword"></el-input>
		</div>
		<div style="margin-top: 20px;">
			<!-- <el-button @click="IdentificationCon()">识别错误</el-button> -->
			<el-button type="primary" round @click="IdentificationResults()">将此答案作为标准答案，对所有错误学生重新进行批改</el-button>
		</div>
	</el-dialog>
</template>

<script>
import {
	queryMacSingList, wrongAnswerCorreted
} from '../../api/Correct.js'
import {
	queryPlanUserJoinDetail,
	queryPlanUserAutoJoinRecords,
	myscriptSave,
} from '../../api/base.js'
export default {
	name: 'Class',
	data() {
		return {
			inputKeyword: null,
			IdentificationResultsShow: false,
			planId: '',
			userId: '',
			pageId: '',
			recorretedData: {
				item: null,
				parentId: null,
				answerAreaitem: null,
			},//点击区域信息
			queryPlanUserAutoJoinRecordsData: {},//识别结果
			//杨老师识别结果要传的参数
			queryPlanUserAutoJoinRecordsDataParmas: {},
			student: {
				penMac: null
			}
		}
	},
	mounted() {
	},
	methods: {
		//初始化
		init() {
			this.userId = this.$parent.userId
			this.planId = this.$parent.planId
			this.pageId = this.$parent.pageId
			this.planData = this.$parent.planData
			this.index = this.$parent.index
			this.recorretedData.item = this.$store.state.$recorretedDatas.item
			this.recorretedData.parentId = this.$store.state.$recorretedDatas.parentId
			this.recorretedData.answerAreaitem = this.index || this.index == 0 ? this.$store.state.$recorretedDatas.item.answerAreaList[this.index] : this.$store.state.$recorretedDatas.answerAreaitem
			this.IdentificationResultsShow = true
			this.queryPlanUserAutoJoinRecords()
			this.QueryMacSingList()
		},
		//识别结果
		queryPlanUserAutoJoinRecords() {
			var that = this
			let data = {
				planId: this.planId,
				questionId: this.recorretedData.item.id,
				serial: this.recorretedData.answerAreaitem.serialAnswer,
				userId: this.userId,
			}
			queryPlanUserAutoJoinRecords(data).then(res => {
				if (res.data.result[0]) {
					this.queryPlanUserAutoJoinRecordsData = res.data.result[0]
					this.queryPlanUserAutoJoinRecordsDataParmas = {
						auto_result: res.data.result[0].auto_result,
						begin_time: res.data.result[0].begin_time,
						end_time: res.data.result[0].end_time,
						result_id: res.data.result[0].result_id,
						sub_id: res.data.result[0].sub_id,
						sub_parent_id: res.data.result[0].sub_parent_id,
						flag: res.data.result[0].flag,
						params: res.data.result[0].params
					}
					that.inputKeyword = this.queryPlanUserAutoJoinRecordsData.content
				} else {
					this.queryPlanUserAutoJoinRecordsData.content = ''
				}
				this.queryPlanUserAutoJoinRecordsData.content = this.textcircled(this.queryPlanUserAutoJoinRecordsData.content)
				this.Mathqunati('zhangcontainer')
			})
		},
		textcircled(val) {
			if (val) {
				let a = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫']
				let data = val.replace(/\\textcircled {(1)}/g, a[0])
				data = data.replace(/\\textcircled {(2)}/g, a[1])
				data = data.replace(/\\textcircled {(3)}/g, a[2])
				data = data.replace(/\\textcircled {(4)}/g, a[3])
				data = data.replace(/\\textcircled {(5)}/g, a[4])
				data = data.replace(/\\textcircled {(6)}/g, a[5])
				data = data.replace(/\\textcircled {(7)}/g, a[6])
				data = data.replace(/\\textcircled {(8)}/g, a[7])
				data = data.replace(/\\textcircled {(9)}/g, a[8])
				data = data.replace(/\\textcircled {(10)}/g, a[9])
				data = data.replace(/\\textcelsius/g, "度")
				data = data.replace(/&amp;nbsp;/g, ' ')
				return data
			}
		},
		Mathqunati(data) {
			this.$nextTick(() => { //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
				if (this.commonsVariable.isMathjaxConfig) { //判断是否初始配置，若无则配置。
					this.commonsVariable.initMathjaxConfig();
				}
				this.commonsVariable.MathQueue(data);
				this.Mathqunatishow = true
			})
		},
		//批量重新批改
		IdentificationResults() {
			let data = {
				"stand_an": this.$store.state.$recorretedDatas.item.questionOutVo.answerList.map(item => {
					return item.correctValue
				}),
				"planId": this.planId,
				"studentId": this.userId,
				"answerId": this.recorretedData.item.questionOutVo.answerList[this.recorretedData.answerAreaitem.serialAnswer - 1].id,
				"pageId": this.pageId,
				"questionId": this.recorretedData.item.id,
				"serial": this.recorretedData.answerAreaitem.serialAnswer,
				// "score": this.recorretedData.answerAreaitem.score ? this.recorretedData.answerAreaitem.score : -1,
				"contentType": this.recorretedData.item.questionOutVo.answerList[this.recorretedData.answerAreaitem.serialAnswer - 1].contentType,
				"languages": this.recorretedData.item.questionOutVo.answerList[this.recorretedData.answerAreaitem.serialAnswer - 1].languages,
				"featureCode": this.recorretedData.item.questionOutVo.featureCode,
				"subjectId": this.recorretedData.item.questionOutVo.subjectId,
				"subjectCode": this.recorretedData.item.questionOutVo.subjectCode,
				"customGrammarId": this.recorretedData.item.questionOutVo.answerList[this.recorretedData.answerAreaitem.serialAnswer - 1].customGrammarId,
				"customResources": this.recorretedData.item.questionOutVo.answerList[this.recorretedData.answerAreaitem.serialAnswer - 1].customResources,
				"manualResult": this.$parent.dialogscoreResult,
				"manualScore": this.$parent.dialogscore,
				"recognitionResult": this.inputKeyword,
				"autoResult": this.queryPlanUserAutoJoinRecordsDataParmas.auto_result,
				"beginTime": this.queryPlanUserAutoJoinRecordsDataParmas.begin_time,
				"endTime": this.queryPlanUserAutoJoinRecordsDataParmas.end_time,
				"resultId": this.queryPlanUserAutoJoinRecordsDataParmas.result_id,
				"subParentId": this.queryPlanUserAutoJoinRecordsDataParmas.sub_parent_id,
				"flag": this.queryPlanUserAutoJoinRecordsDataParmas.flag,
				"params": this.queryPlanUserAutoJoinRecordsDataParmas.params,
				// "queryPlanUserAutoJoinRecordsDataParmas":this.queryPlanUserAutoJoinRecordsDataParmas	
			}
			wrongAnswerCorreted(data).then(res => {
				let that = this
				this.IdentificationResultsShow = false
				this.$emit("wrongAnswerCorretedSuccess", res.status)
				if (this.$store.state.whetherWrong) {
					this.$parent.nextQuestion()
				}

			})
		},
		IdentificationCon() {
			this.IdentificationResultsShow = false
			this.$message("该题识别错误，后续会对该题识别识别改进。")
			this.myScriptSave()
		},
		// 识别正确率
		myScriptSave() {
			let canshu = this.$Download.getQuestionSet(this.planData.resourceRelationList, 'questionSet');
			let data = {
				"content": this.queryPlanUserAutoJoinRecordsData.content,
				"params": this.queryPlanUserAutoJoinRecordsData.params,
				"penMac": this.student.penMac,
				"setId": canshu,
				"subId": this.recorretedData.item.id,
				"userId": this.userId
			}
			myscriptSave(data).then(res => {
				if (this.$store.state.whetherWrong) {
					this.$parent.nextQuestion()
				}
			})
		},
		// 用户的笔
		QueryMacSingList(type, id) {
			let data = {
				userId: this.userId
			}
			if (this.planData.roomId || this.planData.num) {
				data.beginTime = this.planData.beginTime
				data.endTime = this.planData.endTime
			}
			queryMacSingList(data).then(res => {
				this.student.penMac = res.data.result[0].penMac
			})
		},
	}
}
</script>

<style scoped>
::v-deep .el-input__inner {
	margin: 0 auto;
	width: 100px;
	border: 1px solid;
}

.tagText {
	margin-bottom: 20px;
}

.zhangcontainer {
	display: flex;
	justify-content: center;
}

.zhangcontainer span {
	margin: auto 0;
	margin-right: 10px;
}

.el-input {
	width: 100px;
}
</style>
